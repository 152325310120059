<template>
  <div>
    <header class="d-flex align-items-center justify-content-between" style="width: 100%; padding-left: 20px; margin-top:-10px; padding-right: 20px;">
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:60px;"/>
    </header>
    <div>
      <div class="row justify-content-center">
        <!-- Center Column for Personal Data -->
        <div :class=dynamicSize style="margin-left:20px">
          <div class="card mb-2" style="padding: 10px; height: 85vh; overflow-y: auto; overflow-x: hidden;">
            <div class="card mb-3" v-if="nextStep==3">
              <div class="card-body">
                <h5 class="card-title text-center mb-3">Crear cotizacion</h5>
                <div class="mb-3" style="text-align:left">
                  ID de Consulta
              <input type="text" class="form-control" id="name" v-model="order_assessment_id" placeholder="Ingresa el ID de la consulta del paciente" required>
            </div>
                
                <div v-if="this.toggle_search==0" class="d-flex flex-wrap justify-content-between mt-3">
                  <button style="width:35%; margin-left:10%" class="btn btn-primary mb-2" type="button" @click="toggleSearch(1,0)">Buscar comercial</button>
                  <button style="width:35%; margin-right:10%" class="btn btn-primary mb-2" type="button" @click="toggleSearch(1,1)">Buscar magistral</button>
                </div>
                <div class="mb-3" v-if="this.toggle_search==1 && this.search_mode==0">
                  <div class="input-group" >
                    <input type="text" class="form-control" v-model="searchQuery" @input="performSearch" placeholder="Buscar productos comerciales...">
                    <button v-if="this.edit_mode==0" class="btn btn-primary" type="button" @click="addProduct(treatments,treatment_item,1,0)">Agregar</button>
                  </div>
                  <div class="dropdown" v-if="searchResults.length > 0 && searchQuery!=''">
                    <div class="dropdown-item" style="font-size: 14px; text-align: left; margin-top: 10px;" v-for="(drug, index) in searchResults" :key="index" @click="selectProduct(drug)">
                      {{ drug.name }}
                    </div>
                  </div>
                </div>

                <div class="mb-3" v-if="this.toggle_search==1 && this.search_mode==1">
                  <div class="input-group" >
                    <input type="text" class="form-control" v-model="searchFormulaQuery" @input="performFormulaSearch" placeholder="Buscar productos magistrales...">
                    <button v-if="this.edit_mode==0" class="btn btn-primary" type="button" @click="addProduct(treatments,treatment_item,1,1)">Agregar</button>
                  </div>
                  <div class="dropdown" v-if="searchResults.length > 0 && searchFormulaQuery!=''">
                    <div class="dropdown-item" style="font-size: 14px; text-align: left; margin-top: 10px;" v-for="(drug, index) in searchResults" :key="index" @click="selectFormula(drug)">
                      {{ drug.name }}
                    </div>
                  </div>
                  

                  <ul class="list-group mt-3" v-if="treatment_item.name!='' && this.edit_mode==0">
                    <div class="mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center rounded">
                        {{ treatment_item.omicron.name }}
                        <button type="button" class="btn-close" @click="removeTreatment(index)"></button>
                      </li>
                    </div>

                    <div v-if="treatment_item.has_omicron==1 && treatment_item.omicron.dose.length > 0" class="mb-3">
                      <li class="list-group-item d-flex justify-content-between align-items-center rounded">
                        <select v-model="treatment_item.omicron.index" class="form-select" @change="updateSelectedDose">
                          <option v-for="(dose, index) in treatment_item.omicron.dose" :key="index" :value="index">
                            {{ dose }}
                          </option>
                        </select>
                      </li>
                    </div>
                    
                    <div class="d-flex flex-column flex-md-row justify-content-between" style="margin-left:10%; margin-right:10%">
                      <button class="btn btn-primary mb-2 w-100 w-md-25 mx-md-2" type="button" @click="addProduct(treatments,treatment_item,1)">Agregar Producto</button>
                    </div>
                  </ul>
                </div>
                <div v-if="this.treatment_plan.length > 0" class="card mb-2">
                  <div style="text-align: left; margin: 20px;">
                    <ul v-for="(product, index) in this.treatment_plan" :key="index">
                      <i class="fas fa-trash-alt" @click.stop="removeFromTreatmentPlan(index, treatments)" style="margin-right:20px; cursor: pointer;"></i>
                      {{ index + 1 }}. {{ product.name.toUpperCase() }}
                      <span v-if="product.has_omicron==1 && product.omicron.name!=''">{{ product.omicron.name.toUpperCase() }} {{product.omicron.dose[product.omicron.index]}}</span>
                      <div class="input-group" style="width:100px; margin-left: 35px; margin-top:10px">
                        <button class="btn btn-primary" type="button" @click="decreaseQuantity(index)">-</button>
                        <input type="text" class="form-control" v-model="product.quantity" />
                        <button class="btn btn-primary" type="button" @click="increaseQuantity(index)">+</button>  
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-text" style="text-align:left; margin-left: 60%">
              <p>Subtotal: {{ this.calculateSubTotal() }} MXN</p>
              <p>Envio: {{ (150.00).toFixed(2) }} MXN</p>
              <p>Total: {{ this.calculateTotal(this.calculateSubTotal()) }} MXN</p>



            </div>
            <form @submit.prevent="createOrder" v-if="nextStep==3">
              <div class="d-flex flex-column flex-md-row justify-content-between mb-3" style="margin-left:10%; margin-right:10%">
                <button style="width:25%" v-if="this.isAutoFilling==false" type="submit" class="btn btn-primary mb-4 w-100 w-md-25">Crear orden</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from "vue";
import algoliasearch from "algoliasearch/lite";
import Swal from 'sweetalert2';
import { getCurrentInstance } from 'vue';


const client = algoliasearch("Y0B0JKM7OD", "b814ff419be43c1b27c93ed61b23b41a");
const index = client.initIndex("Omicron_pharmacy");
const omicron_index = client.initIndex("Omicron_formulas");

export default {
  setup() {
    const {proxy} = getCurrentInstance();
    const searchQuery = ref("");
    const searchFormulaQuery = ref("");

    const item = ref("");
    const omicron_item = ref("");

    const searchResults = ref([]);
    const searchID = ref("");
    const treatments = ref([]);
    const treatment_item = ref({
      name: '',
      sku: '',
      sku_magistral: '',
      omicron_sku: '',
      price: null,
      priceIVA: null,
      indications: [],
      is_minimal: null,
      has_omicron: null,
      omicron: {
        name: '',
        sku: '',
        price: null,
        price_iva: null,
        doses: null,
        index: null,
      }
    });

    const performSearch = async () => {
      if (searchQuery.value.length % 3 == 0) {
        try {
          const response = await index.search(searchQuery.value, {
            clickAnalytics: true
          });
          searchResults.value = response.hits;
          searchID.value = response.queryID;
        } catch (error) {
          console.error(error);
        }
      }
    };

    const performFormulaSearch = async () => {
      if (searchFormulaQuery.value.length % 3 == 0) {
        try {
          const response = await omicron_index.search(searchFormulaQuery.value, {
            clickAnalytics: true
          });
          searchResults.value = response.hits;
          searchID.value = response.queryID;
        } catch (error) {
          console.error(error);
        }
      }
    };

    const selectProduct = (name) => {
      item.value = name;
      searchResults.value = [];
      searchQuery.value = name.name; // Clear search results after selection
    };

    const selectFormula = (name) => {
      omicron_item.value = name;
      searchResults.value = [];
      searchFormulaQuery.value = name.name; // Clear search results after selection
      addFormula();
    };

    const addTreatment = () => {
      if (searchQuery.value && item.value != '') {
        treatments.value.push(item.value);
        treatment_item.value.name = item.value.name;
        treatment_item.value.sku = item.value.ean;
        treatment_item.value.sku_magistral = item.value.code;
        treatment_item.value.price = item.value.price;
        treatment_item.value.priceIVA = item.value.price_iva;
        treatment_item.value.omicron = {
          name: '',
          sku: 'D3RM4PP',
          price: '[0]',
          price_iva: '[0]',
          dose: '[]',
          index: 0,
        };
        treatment_item.value.has_omicron = 1;
        // get omicron items
        axios.get(`${proxy.$baseURL}/api/get-omicron-formula`, {
          params: {
            code: JSON.parse(item.value.omicron)[0],
          },
        })
          .then((response) => {
            if (response.data.success == true) {
              let omicron = response.data.data;
              treatment_item.value.omicron.name = omicron.name;
              treatment_item.value.omicron.sku = omicron.ean;
              treatment_item.value.omicron.price = omicron.price;
              treatment_item.value.omicron.price_iva = omicron.price_iva;
              treatment_item.value.omicron.dose = formatDoses(omicron.amount, omicron.units);
              if (treatment_item.value.omicron.dose.length > 1) {
                treatment_item.value.omicron.index = 1;
              } else {
                treatment_item.value.omicron.index = 0;
              }
              treatment_item.value.has_omicron = 1;
            }
          });
        searchQuery.value = '';
        searchFormulaQuery.value = '';

        item.value = '';
      }

      else {
        treatments.value.push(searchQuery.value);
        treatment_item.value.name = searchQuery.value;
        treatment_item.value.sku = '0000000000';
        treatment_item.value.sku_magistral = '0000000000';
        treatment_item.value.price = 0;
        treatment_item.value.priceIVA = 0;
        treatment_item.value.omicron = {
          name: '',
          sku: 'D3RM4PP',
          price: '[0]',
          price_iva: '[0]',
          dose: '[]',
          index: 0,
        };
        treatment_item.value.has_omicron = 1;
        treatment_item.value.omicron_sku = '';
        searchQuery.value = '';
        searchFormulaQuery.value = '';

        item.value = '';
      }
    };

    const addFormula = () => {
      if (searchFormulaQuery.value && omicron_item.value != '') {
        treatment_item.value.name = "FORMULA"
        treatment_item.value.omicron.name = omicron_item.value.name;
        treatment_item.value.omicron.sku = omicron_item.value.ean;
        treatment_item.value.omicron.price = omicron_item.value.price;
        treatment_item.value.omicron.price_iva = omicron_item.value.price_iva;
        treatment_item.value.omicron.dose = formatDoses(omicron_item.value.amount, omicron_item.value.units);
        if (treatment_item.value.omicron.dose.length > 1) {
          treatment_item.value.omicron.index = 1;
        } else {
          treatment_item.value.omicron.index = 0;
        }
        treatment_item.value.has_omicron = 1;

        searchQuery.value = '';
        searchFormulaQuery.value = '';

        omicron_item.value = '';
      }
    };

    const formatDoses = (doses, units) => {
      // Remove the first and last characters (the square brackets)
      let formated_doses = doses.slice(1, -1);
      // Split the string by commas and trim any extra spaces from each item
      formated_doses = formated_doses.split(',').map(item => item.trim() + " " + units);

      // Convert each item to a number

      return formated_doses;
    };

    const subsTreatment = (edit_index, treatment_plan) => {
      if (searchQuery.value) {
        treatments.value.splice(edit_index, 1);
        treatments.value.push(item.value);
        treatment_item.value.name = item.value.name;
        treatment_item.value.sku = item.value.ean;
        treatment_item.value.sku_magistral = item.value.code;
        treatment_item.value.price = item.value.price;
        treatment_item.value.priceIVA = item.value.price_iva;
        axios.get(`${proxy.$baseURL}/api/get-omicron-formula`, {
          params: {
            code: JSON.parse(item.value.omicron)[0],
          },
        })
          .then((response) => {
            if (response.data.success == true) {
              let omicron = response.data.data;
              treatment_item.value.omicron.name = omicron.name;
              treatment_item.value.omicron.sku = omicron.ean;
              treatment_item.value.omicron.price = omicron.price;
              treatment_item.value.omicron.price_iva = omicron.price_iva;
              treatment_item.value.omicron.dose = formatDoses(omicron.amount, omicron.units);
              if (treatment_item.value.omicron.dose.length > 1) {
                treatment_item.value.omicron.index = 1;
              } else {
                treatment_item.value.omicron.index = 0;
              }
              treatment_item.value.has_omicron = 1;
            }
          });
        treatment_plan[edit_index].name = treatment_item.value.name,
          treatment_plan[edit_index].sku = treatment_item.value.sku,
          treatment_plan[edit_index].sku_magistral = treatment_item.value.sku_magistral;
        treatment_plan[edit_index].price = treatment_item.value.price,
          treatment_plan[edit_index].priceIVA = treatment_item.value.priceIVA,
          treatment_plan[edit_index].omicron_sku = treatment_item.value.omicron_sku,
          searchQuery.value = '';
        searchFormulaQuery.value = '';

        item.value = '';
      }
    };

    const removeTreatment = (index) => {
      treatments.value.splice(index, 1);
      treatment_item.value.name = '';
      treatment_item.value.sku = '';
      treatment_item.value.sku_magistral = '';
      treatment_item.value.price = null;
      treatment_item.value.priceIVA = null;
      treatment_item.value.is_minimal = null;
      treatment_item.value.has_omicron = null;
    };

    const removeFormula = () => {
      treatment_item.value.omicron.name = "";
      treatment_item.value.omicron.sku = "";
      treatment_item.value.omicron.price = null;
      treatment_item.value.omicron.price_iva = null;
      treatment_item.value.omicron.dose = null;
      treatment_item.value.omicron.index = null;
      treatment_item.value.has_omicron = 0;
    };

    return { formatDoses, searchQuery, searchFormulaQuery, searchResults, performSearch, performFormulaSearch, selectProduct, selectFormula, item, omicron_item, addTreatment, addFormula, removeTreatment, removeFormula, treatments, treatment_item, subsTreatment };
  },

  data() {
    return {
      nextStep: 3,
      treatment_plan: [],
      extended_treatment_plan: [],
      edit_mode: 0,
      edit_index: null,
      isAutoFilling: false,
      toggle_search:0,
      search_mode:0,
      order_assessment_id: "",
      HeaderLogo: require('@/assets/dermapp-white.png'),
    };
  },

  computed: {
    dynamicSize() {
      return {
        'col-md-8': true,
        'col-12': true,
        'mb-3': true,
      };
    },
  },

  methods: {

    decreaseQuantity(index) {
      if (this.treatment_plan[index].quantity > 0) {
        this.treatment_plan[index].quantity--;
      }
    },
    increaseQuantity(index) {
      this.treatment_plan[index].quantity++;
    },

    async createOrder() {
  let checkout = {};
  let products = [];

  let delivery = {
    name: 'Envio nacional',
    sku: 'DHL',
    sku_magistral: 'DHL',
    quantity: 1,
    price: "150.00",
    priceIVA: "150.00",
    dose: '',
    omicron: {
      name: ''
    }
  };

  this.treatment_plan.forEach(treatment => {
    let product = {};
        if (treatment.omicron.name != '') {
      product.name = treatment.omicron.name;
      product.priceIVA = JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index];
      product.quantity = treatment.quantity;
      product.dose = treatment.omicron.dose[treatment.omicron.index];
      product.sku_magistral = treatment.omicron.sku
      products.push(product);
    } else {
      product.name = treatment.name;
      product.priceIVA = treatment.priceIVA;
      product.quantity = treatment.quantity;
      product.dose = "";
      product.sku_magistral = treatment.sku_magistral;
      products.push(product);
    }
      });

  products.push(delivery);
  checkout.products = products;
  checkout.total = this.calculateSubTotal();
  checkout.assessment_id = this.order_assessment_id;

  try {
    // Make a POST request to your API endpoint to create the order
    const response = await axios.post(`${this.$baseURL}/api/create-pharmacy-order`, checkout);
    if (response.status === 200) {
      // If the request was successful, save the new assessment id
      Swal.fire({
        title: '<h3>¡Orden de compra creada exitosamente!</h3>',
        html: '<div style="text-align: left;">' +
          '<small>Se ha creado una nueva orden de compra <br>' +
          'para la consulta: ' + this.order_assessment_id +
          '</small>' +
          '</div>',
        icon: 'success',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#0d6efd',
        showCloseButton: true,
      });    } else {
      console.error('Error creating order:', response);
    }
  } catch (error) {
    console.error('Error creating order:', error);
  }
},

    toggleSearch(status,mode){
      this.toggle_search = status;
      this.search_mode = mode;
    },

    async editTreatmentPlan(index,treatment_item){
        if(this.edit_mode==0){
        this.edit_mode = 1;
        this.edit_index = index;
        //const temp_name = this.treatment_plan[index].name;
        treatment_item.name=this.treatment_plan[index].name;
        treatment_item.sku=this.treatment_plan[index].sku;
        treatment_item.sku_magistral=this.treatment_plan[index].sku_magistral;

        axios.get(`${this.$baseURL}/api/get-omicron-sku`, {
      params: {
        sku: treatment_item.sku,
      },
    })
    .then((response) => { 

      if(response.data.success==true){
        treatment_item.omicron_sku = response.data.data[0].omicron_sku;
      } 
     });
        treatment_item.price = this.treatment_plan[index].price,
        treatment_item.priceIVA = this.treatment_plan[index].priceIVA,
        treatment_item.is_minimal=this.treatment_plan[index].is_minimal;
        treatment_item.indications=this.treatment_plan[index].indications;
        treatment_item.has_omicron = this.treatment_plan[index].has_omicron;
        treatment_item.omicron = this.treatment_plan[index].omicron;
          
        } else {

            this.edit_mode = 0;

//const temp_name = this.treatment_plan[index].name;
let temp_treatment_item = {
  name: treatment_item.name,
  sku: treatment_item.sku,
  sku_magistral: treatment_item.sku_magistral,
  omicron_sku: treatment_item.omicron_sku,
  price: treatment_item.price,
  priceIVA: treatment_item.priceIVA,
  indications: treatment_item.indications,
  is_minimal: treatment_item.is_minimal,
  has_omicron: treatment_item.has_omicron,
  omicron: {
name: treatment_item.omicron.name,
sku: treatment_item.omicron.sku,
price: treatment_item.omicron.price,
price_iva: treatment_item.omicron.price_iva,
dose: treatment_item.omicron.dose,
index: treatment_item.omicron.index,
}
}

if(temp_treatment_item.is_minimal==1){
  this.treatment_plan[this.edit_index]=temp_treatment_item;
} else {
  this.extended_treatment_plan[this.edit_index]=temp_treatment_item;
}

treatment_item.name='';
treatment_item.sku='';
treatment_item.sku_magistral='',
treatment_item.omicron_sku='';
treatment_item.price =null;
treatment_item.priceIVA =null;
treatment_item.is_minimal=null;
treatment_item.indications=[];
treatment_item.has_omicron = null;
treatment_item.omicron = {
name: '',
sku: '',
price: null,
price_iva: null,
dose: null,
index: null,
}
this.edit_index = null;

          }
         

        return treatment_item;
      },

     async  editExtendedTreatmentPlan(index,treatment_item){
        if(this.edit_mode==0){
        this.edit_mode = 1;
        this.edit_index = index;
        //const temp_name = this.treatment_plan[index].name;
        treatment_item.name=this.extended_treatment_plan[index].name;
        treatment_item.sku=this.extended_treatment_plan[index].sku;
        treatment_item.sku_magistral=this.extended_treatment_plan[index].sku_magistral;

        axios.get(`${this.$baseURL}/api/get-omicron-sku`, {
      params: {
        sku: treatment_item.sku,
      },
    })
    .then((response) => { 

      if(response.data.success==true){
        treatment_item.omicron_sku = response.data.data[0].omicron_sku;
      } 
     });
        treatment_item.price = this.extended_treatment_plan[index].price,
        treatment_item.priceIVA = this.extended_treatment_plan [index].priceIVA,
        treatment_item.is_minimal=this.extended_treatment_plan[index].is_minimal;
        treatment_item.indications=this.extended_treatment_plan[index].indications;
        treatment_item.has_omicron = this.extended_treatment_plan[index].has_omicron;
        treatment_item.omicron = this.extended_treatment_plan[index].omicron;

        } else {
        this.edit_mode = 0;
        //const temp_name = this.treatment_plan[index].name;
        let temp_treatment_item = {
          name: treatment_item.name,
          sku: treatment_item.sku,
          sku_magistral: treatment_item.sku_magistral,
          omicron_sku: treatment_item.omicron_sku,
          price: treatment_item.price,
          priceIVA: treatment_item.priceIVA,
          indications: treatment_item.indications,
          is_minimal: treatment_item.is_minimal,
          has_omicron: treatment_item.has_omicron,
          omicron: {
        name: treatment_item.omicron.name,
        sku: treatment_item.omicron.sku,
        price: treatment_item.omicron.price,
        price_iva: treatment_item.omicron.price_iva,
        dose: treatment_item.omicron.dose,
        index: treatment_item.omicron.index,
        },
      }

      if(temp_treatment_item.is_minimal==1){
          this.treatment_plan[this.edit_index]=temp_treatment_item;
        } else {
          this.extended_treatment_plan[this.edit_index]=temp_treatment_item;
        }

        treatment_item.name='';
        treatment_item.sku='';
        treatment_item.sku_magistral='',
        treatment_item.omicron_sku='';
        treatment_item.price =null,
        treatment_item.priceIVA =null,
        treatment_item.is_minimal=null;
        treatment_item.indications=[];
        treatment_item.has_omicron = null;
        treatment_item.omicron = {
        name: '',
        sku: '',
        price: null,
        price_iva: null,
        dose: null,
        index: null,
        }
        this.edit_index = null;
        }    

        return treatment_item;
      },

      
    calculateSubTotal() {
      let total = 0;
      this.treatment_plan.forEach(treatment => {
        total = total + treatment.priceIVA * treatment.quantity / 100
        if (treatment.omicron.name != '') {
      // Parse the price_iva string into an array
      const prices = JSON.parse(treatment.omicron.price_iva);
      total += prices[treatment.omicron.index] * treatment.quantity / 100;
    } 
      });
      return (total * 100).toFixed(2);
    },

    calculateTotal() {
      let total = 1.5;
      this.treatment_plan.forEach(treatment => {
        total = total + treatment.priceIVA *treatment.quantity / 100
        if (treatment.omicron.name != '') {
      // Parse the price_iva string into an array
      const prices = JSON.parse(treatment.omicron.price_iva);
      total += prices[treatment.omicron.index] * treatment.quantity / 100;
    } 
      });
      return (total * 100).toFixed(2);
    },

removeFromTreatmentPlan(index,treatments) {
        this.treatment_plan.splice(index, 1);
        treatments.splice(index,1)
      },


    addProduct(treatments, treatment_item, minimal, mode) {
      if(mode==0){
        this.addTreatment();
        this.removeFormula();
      } else {
        treatment_item.name = ""
      }

      treatment_item.is_minimal = minimal;

      let temp_treatment_item = {
        name: treatment_item.name,
        quantity: 1,
        sku: treatment_item.sku,
        sku_magistral: treatment_item.sku_magistral,
        omicron_sku: treatment_item.omicron_sku,
        price: treatment_item.price,
        priceIVA: treatment_item.priceIVA,
        omicron: {
          name: treatment_item.omicron.name,
          sku: treatment_item.omicron.sku,
          price: treatment_item.omicron.price,
          price_iva: treatment_item.omicron.price_iva,
          dose: treatment_item.omicron.dose,
          index: treatment_item.omicron.index,
        },
        has_omicron: treatment_item.has_omicron,
        indications: treatment_item.indications,
        is_minimal: treatment_item.is_minimal,
      }
      if (treatment_item.is_minimal == 1) {
        this.treatment_plan.push(temp_treatment_item);
        this.toggle_search=0
      } else {
        this.extended_treatment_plan.push(temp_treatment_item);
        treatments.pop();
      }

      treatment_item.name = '',
        treatment_item.sku = '',
        treatment_item.sku_magistral = '',
        treatment_item.omicron_sku = '',
        treatment_item.price = '',
        treatment_item.priceIVA = '',
        treatment_item.indications = [],
        treatment_item.has_omicron = null,
        treatment_item.omicron.name = '',
        treatment_item.omicron.sku = '',
        treatment_item.omicron.price = null,
        treatment_item.omicron.price_iva = null,
        treatment_item.omicron.dose = null,
        treatment_item.omicron.index = null
      treatment_item.is_minimal = null
    },

    confirmDiagnosisCreation() {
      const self = this; // Capture 'this' to use Vue instance inside Swal

      let htmlContent = '<div style="text-align: center;margin-bottom:20px">Verifica que no haya contraindicaciones <br> y que las equivalencias sean correctas</div><ul>';
      htmlContent += `<div style="font-size:14px;text-align:left;">`;

      // Create a section to list all treatments
      if (self.treatment_plan.length > 0) {
        htmlContent += '<ul>';
        self.treatment_plan.forEach(item => {
          let omicronName = item.omicron.sku === 'D3RM4PP' ? "Sin opción magistral" : item.omicron.name;
          htmlContent += `<li>${item.name}<br> (${omicronName})<br><br></li>`;
        });
        htmlContent += '</ul>';
      }

      // Create a section to list all treatments
      if (self.extended_treatment_plan.length > 0) {
        htmlContent += '<ul>';
        self.extended_treatment_plan.forEach(item => {
          let omicronName = item.omicron.sku === 'D3RM4PP' ? "Sin opción magistral" : item.omicron.name;
          htmlContent += `<li>${item.name}<br> (${omicronName})<br><br></li>`;
        });
        htmlContent += '</ul>';
      }

      // Add a single checkbox for verification
      htmlContent += '</div><div style="font-size:14px;text-align: left;">' +
        '<input type="checkbox" id="verifyAll" style="margin-right: 5px;>' +
        '<label for="verifyAll">He verificado las equivalencias y que no haya contraindicaciones</label>' +
        '</div>';

      Swal.fire({
        title: '<h3>Enviar diagnostico</h3>',
        html: htmlContent,
        denyButtonText: 'Regresar',
        denyButtonColor: '#0d6efd',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#0d6efd',
        showCloseButton: true,
        showCancelButton: false,  // Ensuring no extra cancel button
        showDenyButton: true,
        focusConfirm: false,
        didOpen: () => {
          const confirmButton = document.querySelector('.swal2-confirm');
          const denyButton = document.querySelector('.swal2-deny');
          confirmButton.parentNode.insertBefore(denyButton, confirmButton);
        },
        preConfirm: () => {
          // Check that the verification checkbox is checked
          if (!document.getElementById('verifyAll').checked) {
            Swal.showValidationMessage("verifica la información y marca la casilla para continuar");
            return false;
          }
          return true;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.createDiagnosis(); // Call the method to create diagnosis if everything is verified
        }
      });
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.assessment-image {
  width: auto;
  max-height: 60vh;
  max-width: 100%;
  display: block;
  object-fit: cover;
  margin: 0 auto;
}

.header {
  margin-top: -5px;
  position: absolute;
  width: 100%;
  height: 60px;
  background: #36b5ff;
  z-index: 1;
}

.message {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
}

.admin-message {
  text-align: left;
  margin-right: 10px;
  margin-left: 60px;
  background-color: #d0e0f0;
}

.user-message {
  text-align: left;
  margin-right: 60px;
  margin-left: 10px;
  background-color: #b9b9b9;
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.topics button {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 11%;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.frequencies button {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 15%;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.days-row {
  display: inline-block;
  margin-right: 5px;
}

.swal2-confirm {
  float: right !important;
}
.swal2-deny {
  float: left !important;
}
</style>
