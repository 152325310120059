import { createStore } from 'vuex';

export default createStore({
  state: {
    email: '',
    name: '',
    level: '',
    userId: '',
  },
  mutations: {
    setEmail(state, email) {
      state.email = email;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },

    setName(state, name) {
      state.name = name;
    },
    setLevel(state, level) {
      state.level = level;
    },

  },
  actions: {
    updateEmail({ commit }, email) {
      commit('setEmail', email);
    },
    updateUserId({ commit }, userId) {
      commit('setUserId', userId);
    },
    updateName({ commit }, name) {
      commit('setName', name);
    },
    updateLevel({ commit }, level) {
      commit('setLevel', level);
    },
  },
  getters: {
    getEmail: (state) => state.email,
    getUserId: (state) => state.userId,
    getName: (state) => state.name,
    getLevel: (state) => state.level,
  },
});
