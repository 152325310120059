import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Import the store
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDgaw_hXh2_GMBugL5KD1oQfEyffmB7nG4",
  authDomain: "dermapp-remaster.firebaseapp.com",
  projectId: "dermapp-remaster",
  storageBucket: "dermapp-remaster.appspot.com",
  messagingSenderId: "793458562218",
  appId: "1:793458562218:web:937d5a417238f516fcacd3",
  measurementId: "G-CNH7VC7BPK"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };

const vueApp = createApp(App);

// vueApp.config.globalProperties.$baseURL = 'http://localhost:3000';
// vueApp.config.globalProperties.$baseURLPacientes = 'http://localhost:8081';
// vueApp.config.globalProperties.$baseURLDerma = 'http://localhost:8082';
// vueApp.config.globalProperties.$baseURLAdmin = 'http://localhost:8080';

//base urls staging
//vueApp.config.globalProperties.$baseURL = 'https://staging-api.dermapp.com';
//vueApp.config.globalProperties.$baseURLPacientes = 'https://staging.dermapp.com';
//vueApp.config.globalProperties.$baseURLDerma = 'https://staging-dermatologos.dermapp.com';
//vueApp.config.globalProperties.$baseURLAdmin = 'https://staging-admin.dermapp.com';

//base urls prod
 vueApp.config.globalProperties.$baseURL = 'https://api.dermapp.com';
 vueApp.config.globalProperties.$baseURLPacientes = 'https://pacientes.dermapp.com';
 vueApp.config.globalProperties.$baseURLDerma = 'https://dermatologos.dermapp.com';
 vueApp.config.globalProperties.$baseURLAdmin = 'https://admin.dermapp.com';

vueApp.use(router).use(store).mount('#app');