<template>
<div class="container d-flex flex-column align-items-center justify-content-center" style="height: 100vh; width:100vw;">
    <img :src="Logo" alt="CreateLogo" class="create-logo" style="height: 150px;margin-top:60px"/>
    <div class="card-container" style="max-width: 400px; height: 500px;">
    <div class="card">
      <div class="card-body d-flex flex-column justify-content-between"> <!-- Add d-flex, flex-column, and justify-content-between here -->
        <div>
          <h2 class="card-title text-center mb-4">Iniciar sesión</h2>
          <form @submit.prevent="signIn">
            <div class="mb-3">
              <input type="email" class="form-control" id="email" v-model="email" placeholder="Correo electrónico">
            </div>
            <div class="mb-3">
              <input type="password" class="form-control" id="password" v-model="password" placeholder="Contraseña">
            </div>
            <button type="submit" class="btn btn-primary text-white">Iniciar sesión</button>
          </form>
        </div>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: "",
      password: "",
      Logo: require('@/assets/for doctors.png')

    };
  },
  methods: {

    ...mapActions(['updateEmail']),

    async signIn() { 
      try {
        const userCredential = await signInWithEmailAndPassword(getAuth(), this.email, this.password);
        const user = userCredential.user;

        // Obtén el token de ID
        const idToken = await user.getIdToken();


        localStorage.setItem('Token', idToken);
        localStorage.setItem('Email', this.email);
        this.updateEmail(this.email);
        
        this.$router.push('/admin-dashboard');
      } catch (error) {
        console.error('Error signing in:', error);
      }
    },
  },
};
</script>

<style>
body {
  background: #38b6ff !important;
}
</style>