<template>
  <header class="header">
    <nav class="d-flex" style="align-items: center; margin-top: -10px;">
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:60px; margin-left:20px; margin-right:20px"/>
      <input style="width:8%;" type="date" v-model="startDate" class="date-input" @change="updatePatients">
      <input style="width:8%;" type="date" v-model="endDate" class="date-input" @change="updatePatients">
      <input style="border-radius: 5px; width:10%; height:40px; border-width: 1px; border-color:white" type="text" v-model="filterText" placeholder=" Buscar paciente...">                      
  
      <!-- Add margin-left: auto to the first button -->
      <button class="signin-btn" @click="goToAdminDashboard()" style="margin-left: auto; width:8%; margin-right: 20px; height:40px;">Dashboard</button>
      <button class="signin-btn" @click="goToDeliveryDashboard()" style="width:8%; margin-right: 30px; height:40px;">Farmacia</button>
    </nav>
  </header>
  
  <div>
      <div  class="card mb-2" style="margin-left:20px; padding-top:10px; height: 87h; width:97vw">
              <!-- Center Column for Personal Data -->
              <div class="row">

                <div v-if="this.mode==0" class="col-md-4" style="width: 69vw; margin-left: 15px; margin-right: -10px">
                  <div  class="card mb-2" style="padding-right:10px; padding-left:10px; padding-top:10px; height: 85vh;">
                    <div v-if="isLoading" class="loading-spinner">
                      <div class="spinner"></div> <!-- CSS spinner -->
                      <p>Cargando...</p>
                    </div>
                <div class="card mb-2" style="height:82vh;" v-else>
                  <div class="card-body">
                    <div style="display: flex; align-items: center; justify-content: center;">
                      <button
                        :class="filter_mode === 0 ? 'btn btn-primary' : 'btn btn-outline-primary'"
                        style="font-size:12px; width:120px; margin-left:20px;"
                        type="button"
                        @click="toggleFilter(0)"
                      >
                        Consultas ({{assessmentsCount}})
                      </button>
                    
                      <button
                        :class="filter_mode === 1 ? 'btn btn-primary' : 'btn btn-outline-primary'"
                        style="font-size:12px; width:120px; margin-left:20px;"
                        type="button"
                        @click="toggleFilter(1)"
                      >
                        Mensajes ({{messagesCount}})
                      </button>
                    
                      <button
                        :class="filter_mode === 2 ? 'btn btn-primary' : 'btn btn-outline-primary'"
                        style="font-size:12px; width:120px; margin-left:20px;"
                        type="button"
                        @click="toggleFilter(2)"
                      >
                        Opiniones ({{opinionsCount}})
                      </button>
                    
                      <button
                        :class="filter_mode === 3 ? 'btn btn-primary' : 'btn btn-outline-primary'"
                        style="font-size:12px; width:120px; margin-left:20px;"
                        type="button"
                        @click="toggleFilter(3)"
                      >
                        Urgentes ({{urgentCount}})
                      </button>
                    
                      <button
                        :class="filter_mode === 4 ? 'btn btn-primary' : 'btn btn-outline-primary'"
                        style="font-size:12px; width:120px; margin-left:20px;"
                        type="button"
                        @click="toggleFilter(4)"
                      >
                        Intencion ({{intentsCount}})
                      </button>
                    
                      <button
                        :class="filter_mode === 5 ? 'btn btn-primary' : 'btn btn-outline-primary'"
                        style="font-size:12px; width:120px; margin-left:20px;"
                        type="button"
                        @click="toggleFilter(5)"
                      >
                        Fallido ({{failedCount}})
                      </button>
                    
                      <button
                        :class="filter_mode === 6 ? 'btn btn-primary' : 'btn btn-outline-primary'"
                        style="font-size:12px; width:120px; margin-left:20px;"
                        type="button"
                        @click="toggleFilter(6)"
                      >
                        Vacíos ({{emptyCount}})
                      </button>
                    </div>
                    
                    
                    
                    <div class="row" style="font-size:10px; display: flex; text-align: center; margin-bottom: 5px; margin-top:10px; width:98%; margin-left:10px;">
                      <div class="column" style="flex: 0.1; cursor: pointer;">Usuario</div>
                      <div class="column" style="flex: 1; cursor: pointer;">Nombre</div>
                      <div class="column" style="flex: 0.1; cursor: pointer;">Edad</div>
                      <div class="column" style="flex: 0.1; cursor: pointer;">Sexo</div>
                      <div class="column" style="flex: 0.75; cursor: pointer;">Correo</div>
                      <div class="column" style="flex: 0.5; cursor: pointer;">Whatsapp</div>
                      <div class="column" style="flex: 0.3; cursor: pointer;">Abordado</div>
                      <div class="column" style="flex: 0.2; cursor: pointer;">Suscripcion</div>
                      <div class="column" style="flex: 0.3; cursor: pointer;">Mensaje</div>
                      <div class="column" style="flex: 0.3; cursor: pointer;">Etapa</div>


                   </div>
                   <div v-if="this.profiles.length>0" class="card" style="height:25vh; overflow-y: auto; overflow-x:hidden">
                    <div class="card-body" v-if="isDataLoaded">
                      <div 
                        class="row" 
                        :class="{
                          'selected-row': profile.profile_id === this.selectedProfile.profile_id,
                          'red-background': profile.new_message == 5,
                          'yellow-background': profile.type == 3,
                          'green-background': profile.assessments.length > 0 ? profile.assessments[0].exception == 7 : false,
                          'cyan-background': profile.assessments.length > 0 ? profile.assessments[0].exception == 8 : false,

                        }"
                        style="font-size:10px; display: flex; text-align: center; margin-bottom: 5px; cursor: pointer;" 
                        v-for="profile in filteredPatients" 
                        :key="profile.profile_id"
                        @click="setSelectedProfile(profile)"
                      >
                        <div class="column" style="flex: .1; margin-left:10px; text-align: left; ">{{ profile.user_id }}</div>
                        <div class="column" style="flex: 1; margin-left:10px; text-align: left; ">{{ profile.name }}</div>
                        <div class="column" style="flex: 0.1; margin-left:10px; text-align: left; ">{{ calculateAge(profile.birthday) }}</div>
                        <div class="column" style="flex: 0.1; margin-left:10px; text-align: left; ">{{ parseSex(profile.sex) }}</div>
                        <div class="column" style="flex: 0.75; margin-left:10px; text-align: left; ">{{ profile.email }}</div>
                        <div class="column" style="flex: 0.5; " @click="openWhatsapp(profile.phone)">{{ profile.phone }}</div>
                        <div class="column" style="flex: 0.3; ">
                          <div v-if="editingOnboardingStatus === profile.user_id">
                            <select @change="updateOnboardingStatus(profile)">
                              <option v-for="(status,index) in onboardingStatusOptions" :key="index" :value="getOnboardingStatus(profile.onboarded)">{{ status }}</option>
                            </select>
                          </div>
                          <div v-else @click.stop="startEditingOnboardingStatus(profile.user_id)">{{ getOnboardingStatus(profile.onboarded) }}</div>
                        </div>
                        <div class="column" style="flex: 0.2; ">
                          <div v-if="editingSubscriptionStatus === profile.profile_id">
                            <select @change="updateSubscriptionStatus(profile, $event.target.value)">
                              <option disabled selected value="">Select an option</option>
                              <option v-for="(status, index) in subscriptionStatusOptions" :key="index" :value="status">{{ status }}</option>
                            </select>
                          </div>
                          <div v-else @click.stop="startEditingSubscriptionStatus(profile.profile_id)">{{ parseSubscription(profile.subscription) }}</div>
                        </div>
                        <div class="column" style="flex: 0.3; " @click="setChatMode(0)">{{ getUserMessageStatus(profile.new_message) }}</div>
                        <div class="column" style="flex: 0.3; ">
                          <div v-if="editingProfileStage === profile.profile_id">
                            <select @change="updateProfileStage(profile, $event.target.value)">
                              <option disabled selected value="">Select an option</option>
                              <option v-for="(status, index) in profileStageOptions" :key="index" :value="status">{{ status }}</option>
                            </select>
                          </div>
                          <div v-else @click.stop="startEditingProfileStage(profile.profile_id)">{{ parseStage(profile.status) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
          
                    <div v-else class="card" style="height:27vh; overflow-y: auto; overflow-x:hidden">
                      <div class="card-body" v-if="isDataLoaded">
                        <div>
                          Esta lista esta vacia
                        </div>
                        
                      </div>
                    </div>
                    <!-- Pagination Controls -->
                    <div class="pagination" style="display: flex; justify-content: center; margin-top: 10px;">
                      <button 
                        @click="previousPage" 
                        :disabled="currentPage === 1"
                        class="pagination-button"
                      >
                        Anterior
                      </button>

                      <!-- Page Numbers -->
                      <button 
                        v-for="page in pageNumbersToShow" 
                        :key="page" 
                        @click="goToPage(page)" 
                        :class="['pagination-button', { 'active-page': currentPage === page }]"
                      >
                        {{ page }}
                      </button>

                      <button 
                        @click="nextPage" 
                        :disabled="currentPage === totalPages"
                        class="pagination-button"
                      >
                        Siguiente
                      </button>
                    </div>


          <div class="row" style="font-size:10px; display: flex; text-align: center; margin-bottom: 5px; margin-top:10px; width:98%; margin-left:10px;">
                      <div class="column" style="flex: 1; cursor: pointer;">ID</div>
                      <div class="column" style="flex: 1; cursor: pointer;">Estado</div>
                      <div class="column" style="flex: 1; cursor: pointer;">Excepcion</div>
                      <div class="column" style="flex: 1; cursor: pointer;">Fecha de creacion</div>
                      <div class="column" style="flex: 1; cursor: pointer;">Fecha de pago</div>
                      <div class="column" style="flex: 1; cursor: pointer;">Metodo de pago</div>
                      <div class="column" style="flex: 1; cursor: pointer;">Diagnostico</div>
<!--                       <div class="column" style="flex: 1; cursor: pointer;">Tratamiento</div>
                      <div class="column" style="flex: 1; cursor: pointer;">Sintomas</div> -->
                      <div class="column" style="flex: 1; cursor: pointer;">Mensajes</div>

                      <div class="column" style="flex: 1; cursor: pointer;">Dermatologo</div>

          </div>
                    <div class="card" style="height:15vh; overflow-y: auto; overflow-x:hidden">
                      <div class="card-body" v-if="isDataLoaded">
                        
                         <div class="row" 
              style="font-size:10px; display: flex; text-align: center; margin-bottom: 5px; cursor: pointer;" 
              v-for="assessment in selectedProfile.assessments"
              :class="{
                'selected-row': assessment.id === this.current_assessment.id,
                'red-background': assessment.new_message == 5 
              }"
              :key="assessment.id"
              @click="setCurrentAssessment(assessment)">
                <div class="column" style="flex: 0.5; " @click="openAssessment(assessment.id,assessment.is_diagnosed,assessment.assigned_admin_id)">{{ assessment.id }}</div>
                <div class="column" style="flex: 0.5; ">
                  <div v-if="editingAssessmentStatus === assessment.id">
                    <select @change="updateAssessmentStatus(assessment, $event.target.value)">
                      <option v-for="(status, index) in assessmentStatusOptions" 
                              :key="index" 
                              :value="status">
                        {{ status }}
                      </option>
                    </select>
                  </div>
                  <div v-else @click.stop="startEditingAssessmentStatus(assessment.id)">{{ getAssessmentStatus(assessment.status) }}</div>
                </div>
                <div class="column" style="flex: 0.5; ">
                  <div v-if="editingAssessmentExceptionStatus === assessment.id">
                    <select @change="updateAssessmentExceptionStatus(assessment, $event.target.value)">
                      <option v-for="(status, index) in assessmentExceptionStatusOptions" 
                              :key="index" 
                              :value="status">
                        {{ status }}
                      </option>
                    </select>
                  </div>
                  <div v-else @click.stop="startEditingAssessmentExceptionStatus(assessment.id)">{{ getAssessmentExceptionStatus(assessment.exception) }}</div>
                </div>
                <div class="column" style="flex: 0.5; ">
                  {{ getPaidDate(assessment.created_at) }}
                </div>
                <div class="column" style="flex: 0.5; ">
                  {{ getPaidDate(assessment.paid_at) }}
                </div>
                <div class="column" style="flex: 0.5; " @click="setChatMode(2)">
                  {{assessment.payment_method }}
                </div>
                <div class="column" style="flex: 0.5; ">{{ getDiagnosisStatus(assessment.is_diagnosed) }}</div>
<!--                 <div class="column" style="flex: 0.5; ">
                  <div v-if="editingTreatmentStatus === assessment.id">
                    <select @change="updateTreatmentStatus(assessment)">
                      <option v-for="(status,index) in treatmentStatusOptions" 
                      :key="index" :value="getTreatmentStatus(assessment.has_treatment)"  
                      >{{ status }}</option>
                    </select>
                  </div>
                  <div v-else @click.stop="startEditingTreatmentStatus(assessment.id)">{{ getTreatmentStatus(assessment.has_treatment) }}</div>
                </div>
                <div class="column" style="flex: 0.5; ">
                  <div v-if="editingSymptomsStatus === assessment.id">
                    <select @change="updateSymptomsStatus(assessment)">
                      <option v-for="(status,index) in symptomsStatusOptions" 
                      :key="index" :value="getSymptomsStatus(assessment.has_symptoms)"  
                      >{{ status }}</option>
                    </select>
                  </div>
                  <div v-else @click.stop="startEditingSymptomsStatus(assessment.id)">{{ getSymptomsStatus(assessment.has_symptoms) }}</div>
                </div> -->
                <div class="column" style="flex: 0.5; " @click="setChatMode(1)">{{ getMessageStatus(assessment.new_message) }}</div>
                <div class="column" style="flex: 0.5; ">
                  {{ assessment.assigned_admin_id }}
                </div>
              
              </div>
            </div>
          </div>

          <div class="row" style="font-size:10px; display: flex; text-align: center; margin-bottom: 5px; margin-top:10px; width:98%; margin-left:10px;">
            <div class="column" style="flex: 1; cursor: pointer;">ID</div>
            <div class="column" style="flex: 1; cursor: pointer;">Estado</div>
            <div class="column" style="flex: 1; cursor: pointer;">Fecha de creacion</div>

            <div class="column" style="flex: 1; cursor: pointer;">Fecha de pago</div>
            <div class="column" style="flex: 1; cursor: pointer;">Diagnostico</div>
</div>
          <div class="card" style="height:15vh; overflow-y: auto; overflow-x:hidden">
            <div class="card-body" v-if="isDataLoaded">
              
               <div class="row" 
    style="font-size:10px; display: flex; text-align: center; margin-bottom: 5px; cursor: pointer;" 
    v-for="assessment in current_assessment.followups"
    :class="{
      'selected-row': assessment.id === this.current_followup.id,
    }"
    :key="assessment.id"
    @click="setCurrentFollowup(assessment)">
      <div class="column" style="flex: 0.5; " @click="openFollowup(assessment.is_diagnosed)">{{ assessment.id }}</div>
      <div class="column" style="flex: 0.5; ">
        <div v-if="editingFollowupStatus === assessment.id">
          <select @change="updateFollowupStatus(assessment, $event.target.value)">
            <option v-for="(status, index) in followupStatusOptions" 
                    :key="index" 
                    :value="status">
              {{ status }}
            </option>
          </select>
        </div>
        <div v-else @click.stop="startEditingFollowupStatus(assessment.id)">{{ getAssessmentStatus(assessment.status) }}</div>
      </div>
      <div class="column" style="flex: 0.5; ">
        {{ getPaidDate(assessment.created_at) }}
      </div>
      <div class="column" style="flex: 0.5; ">
        {{ getPaidDate(assessment.paid_at) }}
      </div>
      <div class="column" style="flex: 0.5; ">{{ getDiagnosisStatus(assessment.is_diagnosed) }}</div>
    
    </div>
  </div>
</div>
          
                    
                    
          
                  </div>
                </div> 
                  </div>
                </div>

      <!-- Right Column for Consulta Data and Images, etc. -->

      <div class="col-md-4" style="width: 28vw">
        <div v-if="this.chat_mode==1" class="card mb-2" style=" height: 85vh;">
          <h4 style="margin-top:10px; margin-bottom:-10px">Chat de consulta</h4>
            <div class="card-body" style="height:85%;">
              
                <div class="card" style="height:65%; overflow-y: auto; overflow-x:hidden;" >

                  <div v-if="isDataLoaded">

                    <div class="message" v-for="(msg, index) in this.current_assessment.messages" :key="index"
                     :class="{'admin-message': msg.sender === 'admin', 'user-message': msg.sender === 'user'}"
                     @click="toggleDate(index)"
                     style="font-size:10px">
                  {{ msg.message }}
                  <small v-if="visibleDateIndex === index">{{ msg.date }}</small>
                </div>
                  </div>
                </div>

                <div class="input-group mt-2">
                  <textarea 
                    class="form-control" 
                    id="new_message" 
                    v-model="newMessage" 
                    placeholder="Escribe un mensaje..." 
                    @keyup.enter="sendAssessmentMessage"
                    style="height: 100px; font-size: 16px; padding: 10px;"></textarea>
                </div>
                <div class="text-center mt-2">
                  <button style="width:30%;" class="btn btn-primary" type="button" @click="markAsRead">Leido</button>
                  <button style="width:30%; margin-right:10px; margin-left:10px" class="btn btn-primary" type="button" @click="markAsUrgent">Urgente</button>
                  <button style="width:30%;" class="btn btn-primary" type="button" @click="sendAssessmentMessage">Enviar</button>

                  </div>
                  <div style="margin-top:10px;">
                    <label style="width:30%; margin-right:10px" for="images" class="btn btn-primary">Imágenes</label>
                    <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="handleFileChange('images/')">
                    <label style="width:30%;" for="pdfs" class="btn btn-primary">Exámenes</label>
                    <input class="d-none" type="file" id="pdfs" name="pdfs" accept="application/pdf" multiple ref="pdfsRef" @change="handleFileChange('exams/')">
                
                  </div>
              </div>

          </div>
          <div v-if="this.chat_mode==2" class="card mb-2" style=" height: 85vh;">
            <h4 style="margin-top:10px; margin-bottom:-10px">Comprobante de pago</h4>
            <div class="card-body">
              <div class="card" style="width:100%; height:73vh;">
                <div v-if="current_assessment.status>=1&&current_assessment.payment_method!='card'" style="max-width:100%; height: 100%; overflow-y: auto; overflow-x:hidden">
                  <img style="width:auto; max-height: 100%;" class="assessment-image rounded" :src="current_assessment.transactionUrl" alt="Assessment image">
                </div>
                <div v-else-if="current_assessment.status>=1&&current_assessment.payment_method=='card'" style="text-align:center; margin-top: 20px;">
                  <strong>Pago con tarjeta</strong>
                </div>
                <div v-else style="text-align:center; margin-top: 20px;">
                  <strong>Pago pendiente</strong>
                </div>
              </div>
            </div>
            </div>
          <div v-else class="card mb-2" style="height: 85vh;">
            <h4 style="margin-top:10px; margin-bottom:-10px">Chat general</h4>
            <div class="card-body" style="height:85%;">
              <div class="card" style="height:70%; overflow-y: auto; overflow-x:hidden;">
                <div v-if="isDataLoaded">
                  <div class="message" v-for="(msg, index) in this.selectedProfile.user_messages" :key="index"
                     :class="{'admin-message': msg.sender === 'admin', 'user-message': msg.sender === 'user'}"
                     @click="toggleDate(index)"
                     style="font-size:10px">
                     <div v-if="msg.image">
                      <!-- Display the image. Construct the URL if necessary -->
                      <img style="max-width:100%; max-height:400px; height:auto; display: block; border-radius: 5px;" :src="getImageUrl(msg.image)" alt="Chat Image">
                      <small v-if="visibleDateIndex === index">{{ msg.date }}</small>
                    </div>
                    <div v-else>
                      {{ msg.message }}
                      <small v-if="visibleDateIndex === index">{{ msg.date }}</small>
                    </div>
                
                </div>
                </div>
              </div>
              <div class="input-group mt-2">
                <textarea 
                  class="form-control" 
                  id="new_message" 
                  v-model="newMessage" 
                  placeholder="Escribe un mensaje..." 
                  @keyup.enter="sendUserMessage"
                  style="height: 100px; font-size: 16px; padding: 10px;"></textarea>
              </div>
              
              <div class="text-center mt-2">
                <button class="btn btn-primary" type="button" style="width:30%" @click="markUserAsRead">Leido</button>
                <button class="btn btn-primary" type="button" style="width:30%; margin-left:10px; margin-right:10px" @click="markUserAsUrgent">Urgente</button>
                <button class="btn btn-primary" type="button" style="width:30%" @click="sendUserMessage">Enviar</button>

              </div>
            </div>
          </div>
          
          
        </div>
      </div> 

      </div>

    </div>
</template>


<script>
import { mapState } from 'vuex';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // this generates a unique identifier
import { getAuth, onAuthStateChanged } from 'firebase/auth';



export default {
  data() {
    return {
      currentPage: 1,    // The current page number
    pageSize: 50,     // Number of profiles to display per page
      isLoading: true, // Loading state
      startDate: this.getStartDate(),
      endDate: this.getEndDate(),
      filter_mode: 0,
      visibleDateIndex: null, // Track the index of the message with a visible date
      imageFiles: [],
        pdfFiles: [],
        
        isUploading:false,


      mode:0,
      chat_mode:0,
      users: [],
      user: [],
      profile: [],

      assessments: [],
      all_assessments: [],
      profiles: [],
      newMessage: '',
      current_assessment: {},
      current_followup: {},
      HeaderLogo: require('@/assets/dermapp-white.png'),
      isDataLoaded: false,
      filteredUsers: [],

      filteredAssessments: [],
      filterText: "",
      selectedUser: null,
      selectedProfile: null,
      selectedStage: 0,
      selectedState: 0,
      editingOnboardingStatus: null,
      onboardingStatusOptions: ['pendiente', 'abordado', /* other statuses */],
      editingAssessmentStatus: null,
      assessmentStatusOptions: ['selecciona una opcion','sin pagar', 'pagada', 'esperando', 'seguimiento', 'esperando 2' /* other statuses */],
      editingAssessmentExceptionStatus: null,
      assessmentExceptionStatusOptions: ['selecciona una opcion','Todo bien', 'Inconforme', 'Reembolso', 'Canalizado', 'Resuelto', 'Prueba', 'Repetida', 'Comenzo pago', 'Pago fallido' /* other statuses */],
      
      editingFollowupStatus: null,
      followupStatusOptions: ['sin pagar', 'pagada' /* other statuses */],
    
      editingTreatmentStatus: null,
      treatmentStatusOptions: ['pendiente', 'adquirido', /* other statuses */],
      editingSymptomsStatus: null,
      symptomsStatusOptions: ['negativo', 'positivo', /* other statuses */],
      editingSubscriptionStatus: null,
      subscriptionStatusOptions: ['inactiva', 'activa', /* other statuses */],
      editingProfileStage: null,
      profileStageOptions: ['creada', 
      'envio 1',
      'pago 1',
      'tx 1',
      'espera 2',
      'envio 2',
      'pago 2',
      'tx 2',
      'espera 3',
      'envio 3',
      'pago 3',
      'tx 3',
      'espera 4',
      'envio 4',
      'pago 4',
      'tx 4',
    ],

    };
  },
  computed: {
    ...mapState(['email', 'userId', 'name', 'level', 'country']),
    dashboardData() {
      return [
        { title: `${this.startDate} a ${this.endDate}`, data: this.getDataForPeriod('range') },
      ];
    },
    getTransactionUrl() {
  let file_name;
  // Find files with file_type = 2
  const files = this.current_assessment.files.filter(file => file.type === 2);

  // Sort by file_id in descending order to get the latest one
  const latestFile = files.sort((a, b) => b.id - a.id)[0];

  // If a file is found, use its file_name
  if (latestFile) {
    file_name = latestFile.name;
  }

  // Return the constructed URL
  return `https://dermapp-documents.s3.amazonaws.com/receipts/${file_name}`;
},
    buttonStage() {
    return (stage) => {
      if (this.selectedStage === stage) {
        return 'signin-btn';
      } else if (this.filterText !== '' && this.filterProfilesByTextAndStage(stage).length > 0) {
        return 'signin-btn-secondary';
      } else {
        return 'signin-btn-blank';
      }
    };
  },

  buttonState() {
    return (state) => {
      if (this.selectedState === state + this.selectedStage*4) {
        return 'btn-primary';
      } else if (this.filterText !== '' && this.filterProfilesByTextAndState(state + this.selectedStage*4).length > 0) {
        return 'btn-secondary';
      } else {
        return 'btn-outline-secondary';
      }
    };
  },

  pageNumbersToShow() {
    const totalNumbers = 15; // Number of page buttons to show
    let startPage = Math.max(1, this.currentPage - Math.floor(totalNumbers / 2));
    let endPage = startPage + totalNumbers - 1;

    if (endPage > this.totalPages) {
      endPage = this.totalPages;
      startPage = Math.max(1, endPage - totalNumbers + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  },

  combinedFilter() {
    // Constants for filtering
    const filterTextLower = this.filterText.trim().toLowerCase();

    // Filtering profiles by text independently
    let textFilter = [];
    if (filterTextLower) {
      textFilter = this.profiles.filter(profile => {
        const matchesName = profile.name && profile.name.toLowerCase().includes(filterTextLower);
        const matchesEmail = profile.email && profile.email.toLowerCase().includes(filterTextLower);
        const matchesPhone = profile.phone && profile.phone.toLowerCase().includes(filterTextLower);
        const matchesIds =
          profile.assessment_ids &&
          profile.assessment_ids.some(id => id.toString().toLowerCase().includes(filterTextLower));
        const matchesMessages = JSON.stringify(profile.user_messages).toLowerCase().includes(filterTextLower);
        return (
          (matchesName || matchesEmail || matchesPhone || matchesIds || matchesMessages) &&
          profile.status === this.selectedState
        );
      });
    }

    // Filtering profiles by date or other criteria based on filter_mode
    const dateFilter = this.profiles.filter(profile => {
      const statusMatches = profile.status === this.selectedState;
      const profileIdMatches = profile.profile_id === this.selectedProfile.profile_id;
      const stageMatches = profile.stage === this.selectedStage;

      switch (this.filter_mode) {
        case 0:
          return (
            statusMatches &&
            ((profileIdMatches && stageMatches) || profile.status === this.selectedState)
          );
        case 1:
          return (
            statusMatches &&
            (profileIdMatches || profile.new_message === 1 || profile.new_message === 5)
          );
        case 2:
          return statusMatches && (profileIdMatches || profile.type === 3);
        case 3:
          return statusMatches && (profileIdMatches || profile.new_message === 5);
        case 4:
          return (
            statusMatches &&
            (profileIdMatches ||
              (profile.assessments &&
                profile.assessments.length > 0 &&
                profile.assessments[0].exception === 7))
          );
        case 5:
          return (
            statusMatches &&
            (profileIdMatches ||
              (profile.assessments &&
                profile.assessments.length > 0 &&
                profile.assessments[0].exception === 8))
          );
        default:
          return (
            statusMatches &&
            (profileIdMatches ||
              !profile.assessments ||
              profile.assessments.length === 0)
          );
      }
    });

    // Combining filters: if filterText is present, use text filter; otherwise, use date filter
    return filterTextLower ? textFilter : dateFilter;
  },
  
  filteredPatients() {
    const combinedFilter = this.combinedFilter;

    // Implement pagination: display profiles for the current page
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    return combinedFilter.slice(startIndex, endIndex);
  },

  totalPages() {
    const combinedFilter = this.combinedFilter;
    return Math.ceil(combinedFilter.length / this.pageSize);
  },


assessmentsCount() {
  

  return this.profiles.length

},

messagesCount() {
  

  let messages_count = this.profiles.filter(profile => {
    return profile.new_message === 1 || profile.new_message === 5;
  });

  return messages_count.length

},

urgentCount() {
  

  let messages_count = this.profiles.filter(profile => {
    return profile.new_message === 5;
  });

  return messages_count.length

},

opinionsCount() {
  

  let opinions_count = this.profiles.filter(profile => {
    return profile.type === 3;
  });

  return opinions_count.length

},

intentsCount() {
  

  let opinions_count = this.profiles.filter(profile => {
    return profile.assessments.length > 0 && profile.assessments[0].exception == 7;
  });

  return opinions_count.length

},

failedCount() {
  

  let opinions_count = this.profiles.filter(profile => {
    return profile.assessments.length > 0 && profile.assessments[0].exception == 8
  });

  return opinions_count.length

},

emptyCount() {
  let opinions_count = this.profiles.filter(profile => {
    return !profile.assessments || profile.assessments.length === 0;
  });

  return opinions_count.length;
},





  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/'); // Redirigir al login
      }
    });
    this.token = localStorage.getItem('Token');

  // Fetch the user data
  this.isLoading=true;
  axios.get(`https://api.dermapp.com/api/support-profiles-v4?startDate=${this.startDate}&endDate=${this.endDate}`,
    {
      headers: {
          'Authorization': `Bearer ${this.token}`
        }
    })
    .then((profiles_response) => {
      this.profiles = profiles_response.data.data.profiles;
      this.setSelectedProfile(this.profiles[0]);
      this.isDataLoaded=true;
      this.isLoading=false;
    })
     .catch((error) => {
      console.error("Error fetching profiles data:", error);
    });
},

  methods: {

    goToPage(pageNumber) {
    if (pageNumber >= 1 && pageNumber <= this.totalPages) {
      this.currentPage = pageNumber;
    }
  },
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage += 1;
    }
  },
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
    }
  },

    startEditingFollowupStatus(assessmentId) {
    this.editingFollowupStatus = assessmentId;
  },

    setCurrentFollowup(assessment){
   
   this.current_followup = assessment;
   
 },

    async updateFollowupStatus(assessment, status) {

let temp_status = null;

switch(status) {
  case 'sin pagar': temp_status = 0; break;
  case 'pagada': temp_status = 1; break;

  default: return "unknown"; // Optional, for values other than 0, 1, or 2
}

let payment_data = {
  assessment_id: this.current_assessment.id,
  followup_assessment_id: assessment.id,
  status: temp_status,
  payment_method: "wire-transfer/deposite",
}
await axios.put('https://api.dermapp.com/api/update-followup-payment', payment_data);
assessment.status=temp_status;
this.editingFollowupStatus = null;

},

    async updatePatients(){
      this.isLoading=true;
      let profiles_response =await   axios.get(`https://api.dermapp.com/api/support-profiles-v4?startDate=${this.startDate}&endDate=${this.endDate}`,
    {
      headers: {
          'Authorization': `Bearer ${this.token}`
        }
    })
      this.profiles = profiles_response.data.data.profiles;
      this.setSelectedProfile(this.profiles[0]);
      this.isLoading=false;

    },

    getStartDate() {
  const date = new Date();
  date.setDate(date.getDate() - 7); // Subtract 30 days from today's date
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
},
getEndDate() {
  const date = new Date();
  date.setDate(date.getDate() + 1); // Add 1 day to get tomorrow's date
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
},

    toggleFilter(mode){
this.filter_mode=mode;
this.currentPage= 1;    // The current page number

    },

    toggleDate(index) {
      if (this.visibleDateIndex === index) {
        this.visibleDateIndex = null; // Hide the date if the same message is clicked again
      } else {
        this.visibleDateIndex = index; // Show the date for the clicked message
      }
    },

    async sendUserMessage() {
      if(this.newMessage.trim() !== '') {
        if(this.selectedProfile.user_messages==null){
          this.selectedProfile.user_messages = [];
        }
        this.selectedProfile.user_messages.push({ sender: 'admin', message: this.newMessage, date: new Date().toLocaleString() });
        this.selectedProfile.new_message = 2;
        let updatedMessages = {
          user_id: this.selectedProfile.user_id,
          messages: this.selectedProfile.user_messages,
          date: new Date().toLocaleString(),
        }
        
        this.newMessage = '';
        await axios.put(`${this.$baseURL}/api/update-user-messages`, updatedMessages);

      }
    },

    async handleFileChange(folder) {
        let selectedFiles = [...event.target.files];
        let new_total = this.imageFiles.length + selectedFiles.length;
        let new_total_pdf = this.pdfFiles.length + selectedFiles.length;
        if(selectedFiles.length>0){
          this.isUploading = true;
        }

        for (let i = 0; i<selectedFiles.length; i++){
          let tempFile = {
          file: selectedFiles[i],
          status: 'Subiendo...',
        }
          if(folder=='images/'){
            this.imageFiles.push(tempFile);
            await this.uploadToS3(this.imageFiles[this.imageFiles.length-1].file, folder, this.imageFiles.length-1, new_total);
          } else {
            this.pdfFiles.push(tempFile);
            await this.uploadToS3(this.pdfFiles[this.pdfFiles.length-1].file, folder, this.pdfFiles.length-1, new_total_pdf);
          }
        }
        
    },

    async uploadToS3(file, folder, position, total) {
  const fileExtension = file.name.split('.').pop();
  const newFileName = `${uuidv4()}.${fileExtension}`; // creates a new unique file name
  const fileName = `${folder}${newFileName}`;
  const destination = 'dermapp-documents';
  const availability = 'public-read';

  const formData = new FormData();
  formData.append('destination', destination);
  formData.append('fileName', fileName);
  formData.append('file', file);
  formData.append('availability', availability);

  try {
    await axios.post(`${this.$baseURL}/api/upload-to-s3`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    await this.uploadToDB(newFileName, fileExtension, folder, position, total);
  } catch (error) {
    this.imageFail = true;
    this.isUploading = false;
    if(folder == 'images/'){
              this.imageFiles[position].status = 'Cancelado';
            }
            else{
              this.pdfFiles[position].status = 'Cancelado';
            }
    console.error('Error uploading files to s3:', error);
  }
},

    async uploadToDB(fileName, fileExtension, folder, position, total) {
      const token = localStorage.getItem('Token');
        let fileType = folder === 'images/' ? 0 : 1;
        if(this.current_assessment.status>1) {
          try {
          let followup_assessment = await axios.get(`${this.$baseURL}/api/get-latest-followup-assessment`, {
            params: {
              assessment_id: this.current_assessment.id,
              mode: this.current_assessment.status,
            },
            headers: {
                'Authorization': `Bearer ${token}`
              }
          });
          const files_data = {
          followup_assessment_id: followup_assessment.data.data.id,
          file_name: fileName,
          file_extension: fileExtension,
          file_type: fileType,
        }
            await axios.post(`${this.$baseURL}/api/assessment-followup-files`, files_data);

            if(position+1==total){
              if(folder == 'images/'){
                alert("Las imagenes fueron enviadas");
                if(this.current_assessment.messages==null){
          this.current_assessment.messages = [];
        }
        this.current_assessment.messages.push({ sender: 'user', message: "Se enviaron nuevas imagenes de seguimiento", date: new Date().toLocaleString() });
            }
            else{
              alert("Los examenes fueron enviados");
              if(this.current_assessment.messages==null){
          this.current_assessment.messages = [];
        }
        this.current_assessment.messages.push({ sender: 'user', message: "Se enviaron nuevos estudios", date: new Date().toLocaleString() });
            }
        let updatedMessages = {
          assessment_id: this.current_assessment.id,
          messages: this.current_assessment.messages,
          date: new Date().toLocaleString(),
        }
        await axios.put(`${this.$baseURL}/api/update-assessment-messages`, updatedMessages);
            }


        } catch (error) {
            console.error('Error creating assessment:', error);
            throw error; // Propagate the error so we can handle it in the calling function
        }
        } else {
          try {
            const files_data = {
                assessment_id: this.current_assessment.id,
                file_name: fileName,
                file_extension: fileExtension,
                file_type: fileType,
            }
            await axios.post(`${this.$baseURL}/api/assessment-files`, files_data);

            if(position+1==total){
              if(folder == 'images/'){
                alert("Las imagenes fueron enviadas");
                if(this.current_assessment.messages==null){
          this.current_assessment.messages = [];
        }
        this.current_assessment.messages.push({ sender: 'user', message: "Se enviaron nuevas imagenes", date: new Date().toLocaleString() });
            }
            else{
              alert("Los examenes fueron enviados");
              if(this.current_assessment.messages==null){
          this.current_assessment.messages = [];
        }
        this.current_assessment.messages.push({ sender: 'user', message: "Se enviaron nuevos estudios", date: new Date().toLocaleString() });
            }
        let updatedMessages = {
          assessment_id: this.current_assessment.id,
          messages: this.current_assessment.messages,
          date: new Date().toLocaleString(),
        }
        await axios.put(`${this.$baseURL}/api/update-assessment-messages`, updatedMessages);
            }


        } catch (error) {
            console.error('Error creating assessment:', error);
            throw error; // Propagate the error so we can handle it in the calling function
        }
        }
        
    },

    filterProfilesByTextAndStage(state) {
    if (this.filterText !== "") {
      const filterTextLower = this.filterText.toLowerCase();
      let statusFilteredProfiles = this.profiles.filter(profile => profile.stage === state);
     return statusFilteredProfiles.filter(profile => {
    let matchesName = profile.name && profile.name.toLowerCase().includes(filterTextLower);
    let matchesEmail = profile.email && profile.email.toLowerCase().includes(filterTextLower);
    let matchesPhone = profile.phone && profile.phone.toLowerCase().includes(filterTextLower);
    let matchesIds = profile.assessment_ids.some(id => id.toString().toLowerCase().includes(filterTextLower));
    return matchesName || matchesEmail || matchesPhone || matchesIds;
  });} else {
      return this.profiles.filter(profile => profile.stage === state);
    }
  },

  filterProfilesByTextAndState(state) {
    if (this.filterText !== "") {
      const filterTextLower = this.filterText.toLowerCase();
      let statusFilteredProfiles = this.profiles.filter(profile => profile.status === state);
      return statusFilteredProfiles.filter(profile => {
    let matchesName = profile.name && profile.name.toLowerCase().includes(filterTextLower);
    let matchesEmail = profile.email && profile.email.toLowerCase().includes(filterTextLower);
    let matchesPhone = profile.phone && profile.phone.toLowerCase().includes(filterTextLower);
    let matchesIds = profile.assessment_ids.some(id => id.toString().toLowerCase().includes(filterTextLower));
    return matchesName || matchesEmail || matchesPhone || matchesIds;
  });} else {
      return this.profiles.filter(profile => profile.status === state);
    }
  },

    setSelectedStage(stage){
   this.selectedStage = stage;

 },

 setSelectedState(state){
   this.selectedState = state + this.selectedStage*4;

 },

    getPaidDate(date) {
      if(date) {
        return this.formatDate(date)
      } else {
        return "-"
      }
    },

   formatDate(dateString) {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Adjust based on preference for 24-hour or 12-hour format
    };

    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Subtract 6 hours from the date
    date.setHours(date.getHours());

    // Return the adjusted date formatted as a string
    return date.toLocaleDateString('es-ES', options);
},


    getImageUrl(imagePath) {
        // Assuming imagePath is the folder and file name
        // Replace 'your-s3-bucket-url' with your actual S3 bucket URL
        return `https://dermapp-documents.s3.amazonaws.com/${imagePath}`;
    },

    sortUsersByMessageStatus() {
    this.filteredProfiles.sort((a, b) => b.new_message - a.new_message);
  },


sortUsersByOnboardingStatus() {
    this.filteredProfiles.sort((a, b) => a.onboarded - b.onboarded);
  },


    toggleMode(mode){
      this.mode=mode;
      if(this.mode==1){
        this.filterAssessmentsByDate();
      }
    },

    setChatMode(mode){
      this.chat_mode=mode;
    },

    startEditingOnboardingStatus(userId) {
    this.editingOnboardingStatus = userId;
  },

  async updateOnboardingStatus(profile) {
    //const newStatus = event.target.value;
    let id = {
      user_id: profile.user_id,
    }
  await axios.put(`${this.$baseURL}/api/update-onboarding`, id);
    profile.onboarded=1;
    this.editingOnboardingStatus = null;

  },

  startEditingTreatmentStatus(assessmentId) {
    this.editingTreatmentStatus = assessmentId;
  },

  async updateTreatmentStatus(assessment) {
    //const newStatus = event.target.value;
    let id = {
      assessment_id: assessment.id,
    }
  await axios.put(`${this.$baseURL}/api/update-has-treatment`, id);
    assessment.has_treatment=1;
    this.editingTreatmentStatus = null;

  },

  startEditingSymptomsStatus(assessmentId) {
    this.editingSymptomsStatus = assessmentId;
  },

  async updateSymptomsStatus(assessment) {
    //const newStatus = event.target.value;
    let id = {
      assessment_id: assessment.id,
    }
  await axios.put(`${this.$baseURL}/api/update-has-symptoms`, id);
    assessment.has_symptoms=1;
    this.editingSymptomsStatus = null;

  },

  startEditingAssessmentStatus(assessmentId) {
    this.editingAssessmentStatus = assessmentId;
  },
  startEditingAssessmentExceptionStatus(assessmentId) {
    this.editingAssessmentExceptionStatus = assessmentId;
  },
  startEditingSubscriptionStatus(profileId) {
    this.editingSubscriptionStatus = profileId;
  },
  startEditingProfileStage(profileId) {
    this.editingProfileStage = profileId;
  },

  async updateAssessmentStatus(assessment, status) {

    let temp_status = null;

    switch(status) {
      case 'sin pagar': temp_status = 0; break;
      case 'pagada': temp_status = 1; break;
      case 'esperando': temp_status = 2; break;
      case 'seguimiento': temp_status = 3; break;
      case 'esperando 2': temp_status = 4; break;

      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }

    let payment_data = {
      assessment_id: assessment.id,
      status: temp_status,
      payment_method: "wire-transfer/deposite",
      phone: this.selectedProfile.phone,
      email: this.selectedProfile.email,
      patient_name: this.selectedProfile.name.split(" ")[0],
    }
  await axios.put(`${this.$baseURL}/api/update-assessment-payment`, payment_data);
  if(temp_status == 6 || temp_status == 8) {
    temp_status = temp_status - 4;
    }
    assessment.status=temp_status;
    this.editingAssessmentStatus = null;

  },

  async updateAssessmentExceptionStatus(assessment, status) {

let temp_status = null;

switch(status) {
  case 'Todo bien': temp_status = 0; break;
  case 'Inconforme': temp_status = 1; break;
  case 'Reembolso': temp_status = 2; break;
  case 'Canalizado': temp_status = 3; break;
  case 'Resuelto': temp_status = 4; break;
  case 'Prueba': temp_status = 5; break;
  case 'Repetida': temp_status = 6; break;
  case 'Comenzo pago': temp_status = 7; break;
  case 'Pago fallido': temp_status = 8; break;


  default: return "unknown"; // Optional, for values other than 0, 1, or 2
}

let exception_status = {
  assessment_id: assessment.id,
  status: temp_status,
}
await axios.put(`${this.$baseURL}/api/update-assessment-exception`, exception_status);

assessment.exception=temp_status;
this.editingAssessmentExceptionStatus = null;

},

  async updateSubscriptionStatus(profile,status) {
    let temp_status = null;
    if(status == "inactiva") {
      temp_status = 0;
    } else {
      temp_status = 1;
    }
    
    //const newStatus = event.target.value;
    let subscription_data = {
      profile_id: profile.profile_id,
      status: temp_status,
    }
  await axios.put(`${this.$baseURL}/api/update-subscription-status`, subscription_data);
    profile.subscription=temp_status;
    this.editingSubscriptionStatus = null;

  },

  async updateProfileStage(profile,status) {
    let temp_status = null;
    let temp_stage = null;
    switch(status) {
      case 'creada': temp_status = 0; temp_stage = 0; break;
      case 'envio 1': temp_status = 1; temp_stage = 0; break;
      case 'pago 1': temp_status = 2; temp_stage = 0; break;
      case 'tx 1': temp_status = 3;  temp_stage = 0; break;
      case 'espera 2': temp_status = 4; temp_stage = 1; break;
      case 'envio 2': temp_status = 5; temp_stage = 1; break;
      case 'pago 2': temp_status = 6; temp_stage = 1; break;
      case 'tx 2': temp_status = 7; temp_stage = 1; break;
      case 'espera 3': temp_status = 8; temp_stage = 2; break;
      case 'envio 3': temp_status = 9; temp_stage = 2; break;
      case 'pago 3': temp_status = 10; temp_stage = 2; break;
      case 'tx 3': temp_status = 11; temp_stage = 2; break;
      case 'espera 4': temp_status = 12; temp_stage = 3; break;
      case 'envio 4': temp_status = 13; temp_stage = 3; break;
      case 'pago 4': temp_status = 14; temp_stage = 3; break;
      case 'tx 4': temp_status = 15; temp_stage = 3; break;

      

      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
    
    //const newStatus = event.target.value;
    let stage_data = {
      profile_id: profile.profile_id,
      status: temp_status,
      stage: temp_stage
    }
  await axios.put(`${this.$baseURL}/api/update-profile-stage`, stage_data);
    profile.status=temp_status;
    profile.stage = temp_stage;
    this.editingProfileStage = null;

  },

    openWhatsapp(phone) {
        let digitsOnly = '';

    // Check if phone starts with a '+', remove it
    if (phone.startsWith('+')) {
        digitsOnly = phone.substring(1);
    }

    // Construct the URL with the processed phone number
    let url = `https://api.whatsapp.com/send?phone=${digitsOnly}`;

    // Open the URL
    window.open(url, '_blank');
},

openAssessment(id,is_diagnosed,assigned_admin_id) {
    
    if(is_diagnosed==0 ){
      let url = `https://dermatologos.dermapp.com/create-diagnosis-new/${id}?userId=${assigned_admin_id}`;
      window.open(url, '_blank');

    } else {
      let url = `https://pacientes.dermapp.com/assessment-diagnosis-new/${id}`;
      window.open(url, '_blank');
       }

},

openFollowup(is_diagnosed) {
    
      if(is_diagnosed == 0) {
      let url = `https://dermatologos.dermapp.com/create-followup-diagnosis-new/${this.current_assessment.id}?userId=${this.current_assessment.assigned_admin_id}`;
      window.open(url, '_blank');

    } else {
      let url = `https://pacientes.dermapp.com/followup-assessment-diagnosis-new/${this.current_assessment.id}`;
      window.open(url, '_blank');
    }

},

async getAssessmentFiles(assessment){
  const token = localStorage.getItem('Token');

  try {
    const response = await axios.get(`${this.$baseURL}/api/get-files`, {
      params: {
        assessment_id: assessment.id,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    const transactionFiles = response.data.data.filter(file => file.file_type === 2);

    if(transactionFiles && transactionFiles.length>0){
      assessment.transactionUrl =`https://dermapp-documents.s3.amazonaws.com/receipts/${transactionFiles[transactionFiles.length-1].file_name}`;

     this.isTransactionDataLoaded= true;
    }
        

} catch (error) {
    console.error('Error fetching trasnaction files:', error);
  }
},

  setCurrentAssessment(assessment){
    this.getAssessmentFiles(assessment);
    this.current_assessment = assessment;
    try{
      this.current_assessment.messages = JSON.parse(this.current_assessment.messages);
    } catch {
      console.log("continue");
    }
  },

  async setSelectedProfile(profile){
    const token = localStorage.getItem('Token');
    let updated_messages = await axios.get(`${this.$baseURL}/api/get-user-messages`, {
      params: {
        user_id: profile.user_id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        },
    });

    profile.user_messages == updated_messages;
   
    this.selectedProfile = profile;
   try{
     this.selectedProfile.user_messages = JSON.parse(this.selectedProfile.user_messages);
   } catch {
     console.log("continue");
   }
   if(this.selectedProfile.assessments.length>0){
    this.setCurrentAssessment(this.selectedProfile.assessments[0]);
   }
 },

  calculateAge(birthday) {
    const birthdate = new Date(birthday);
    const ageDifMs = Date.now() - birthdate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },

      parseSex(sex){
        if(sex=='Male'){
          return 'M';
        } else {
          return 'F';
        }
      },

      parseSubscription(subscription){
        if(subscription==0){
          return 'inactiva';
        } else {
          return 'activa';
        }
      },

      parseStage(status) {
    switch(status) {
      case 0: return "creada";
      case 1: return "envio 1";
      case 2: return "pago 1";
      case 3: return "tx 1";
      case 4: return "espera 2";
      case 5: return "envio 2";
      case 6: return "pago 2";
      case 7: return "tx 2";
      case 8: return "espera 3";
      case 9: return "envio 3";
      case 10: return "pago 3";
      case 11: return "tx 3";
      case 12: return "espera 4";
      case 13: return "envio 4";
      case 14: return "pago 4";
      case 15: return "tx 4";


      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
  },

    getMessageStatus(newMessageValue) {
    switch(newMessageValue) {
      case 0: return "-";
      case 1: return "esperando";
      case 2: return "nuevo";
      case 5: return "urgente";

      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
  },

  getUserMessageStatus(newMessageValue) {
    switch(newMessageValue) {
      case 0: return "-";
      case 1: return "nuevo";
      case 2: return "esperando";
      case 5: return "urgente";

      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
  },

  getAssessmentStatus(newMessageValue) {
    switch(newMessageValue) {
      case 0: return "sin pagar";
      case 1: return "pagada";
      case 2: return "esperando";
      case 3: return "seguimiento";
      case 4: return "esperando 2";
      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
  },

  getAssessmentExceptionStatus(newMessageValue) {
    switch(newMessageValue) {
      case 0: return "Todo bien";
      case 1: return "Inconforme";
      case 2: return "Reembolso";
      case 3: return "Canalizado";
      case 4: return "Resuelto";
      case 5: return "Prueba";
      case 6: return "Repetida";
      case 7: return "Comenzo pago";
      case 8: return "Pago fallido";

      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
  },

  
  getOnboardingStatus(value) {
    switch(value) {
      case 0: return "pendiente";
      case 1: return "abordado";
      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
  },

    
  getDiagnosisStatus(value) {
    switch(value) {
      case 0: return "pendiente";
      case 1: return "disponible";
      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
  },

  getTreatmentStatus(value) {
    switch(value) {
      case 0: return "pendiente";
      case 1: return "adquirido";
      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
  },

  getSymptomsStatus(value) {
    switch(value) {
      case 0: return "negativo";
      case 1: return "positivo";
      default: return "unknown"; // Optional, for values other than 0, 1, or 2
    }
  },

    async sendAssessmentMessage() {
      if(this.newMessage.trim() !== '') {
        if(this.current_assessment.messages==null){
          this.current_assessment.messages = [];
        }
        this.current_assessment.messages.push({ sender: 'admin', message: this.newMessage, date: new Date().toLocaleString() });
        this.current_assessment.new_message = 1;
        let updatedMessages = {
          assessment_id: this.current_assessment.id,
          messages: this.current_assessment.messages,
          date: new Date().toLocaleString()
        }
        
        this.newMessage = '';
        await axios.put(`${this.$baseURL}/api/update-assessment-messages`, updatedMessages);

      }
    },

    async sendProfileMessage() {
      if(this.newMessage.trim() !== '') {
        if(this.selectedProfile.user_messages==null){
          this.selectedProfile.user_messages = [];
        }
        this.selectedProfile.user_messages.push({ sender: 'admin', message: this.newMessage, date: new Date().toLocaleString() });
        this.selectedProfile.new_message = 1;
        let updatedMessages = {
          user_id: this.selectedProfile.user_id,
          messages: this.selectedProfile.user_messages,
          date: new Date().toLocaleString(),
        }
        
        this.newMessage = '';
        await axios.put(`${this.$baseURL}/api/update-user-messages`, updatedMessages);

      }
    },

    async markAsRead() {
        this.current_assessment.new_message = 0;
        let updatedMessages = {
          assessment_id: this.current_assessment.id, 
        }
        await axios.put(`${this.$baseURL}/api/update-read-message`, updatedMessages);
    },

    async markUserAsRead() {
        this.selectedProfile.new_message = 0;
        let updatedMessages = {
          user_id: this.selectedProfile.user_id, 
        }
        await axios.put(`${this.$baseURL}/api/update-user-read-message`, updatedMessages);
    },

    async markAsUrgent() {
        this.current_assessment.new_message = 5;
        let updatedMessages = {
          assessment_id: this.current_assessment.id, 
        }
        await axios.put(`${this.$baseURL}/api/update-urgent-message`, updatedMessages);
    },

    async markUserAsUrgent() {
        this.selectedProfile.new_message = 5;
        let updatedMessages = {
          user_id: this.selectedProfile.user_id, 
        }
        await axios.put(`${this.$baseURL}/api/update-user-urgent-message`, updatedMessages);
    },

    goToAdminDashboard() {
      this.$router.push('/admin-dashboard');
    },

    goToDeliveryDashboard() {
      this.$router.push('/delivery-dashboard');
    },




   
  },
};
</script>

<style scoped>



.header {
    width: 100%;
    height: 60px;
    background: #36b5ff;
    z-index: 1;
  }

  .date-selector {
    text-align: left;
    margin-bottom: 10px;
  }

  .date-input {
    padding: 8px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  
.signin-btn {
  background: white;
  color: #36b5ff;
  border: none;
  height: 30px;
  padding-right:10px;
  padding-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
  }

  .signin-btn-blank {
    background: #36b5ff;
    color: white;
    border: 1px solid white; /* Ensures border color and style */
    height: 30px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
    outline: none; /* Removes focus outline */
    box-shadow: none; /* Ensures no shadow is applied */
}

.signin-btn-secondary {
  background: gray;
  color: white;
  border: gray; /* Ensures border color and style */
  height: 30px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
  outline: none; /* Removes focus outline */
  box-shadow: none; /* Ensures no shadow is applied */
}

  
  .admin-message {
    text-align: left;
    margin-right: 10px;
    margin-left: 60px;
    background-color: #d0e0f0;
  }
  
  .user-message {
    text-align: left;
    margin-right: 60px;
    margin-left: 10px;
    background-color: #b9b9b9;
  }

  .message {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .red-background {
    background-color: #FF0A77;
  }

  .yellow-background {
    background-color: #FFD817;
  }

  .green-background {
    background-color: #96ff38;
  }

  .cyan-background {
    background-color: #3bffff;
  }

  .magenta-background {
    background-color: #3846ff;
  }
  
  .selected-row {
    background-color: #e0e0e0; /* Example: light gray background */
    /* Other styling as needed */
  }

  .loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .spinner {
    border: 4px solid;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #09f;
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      background-color: #19d2ff; /* First color */
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      background-color: #0d80ff; /* Second color */
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      background-color: #19d2ff; /* Third color */
      opacity: 1;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .pagination-button {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #007bff;
    padding: 5px 10px;
    margin: 0 2px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .pagination-button:hover {
    background-color: #e2e6ea;
  }
  
  .pagination-button:disabled {
    color: #6c757d;
    cursor: not-allowed;
  }
  
  .active-page {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  

</style>
  