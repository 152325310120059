import { createRouter, createWebHistory } from 'vue-router';
import SignIn from '@/views/SignIn.vue';
import adminDashboard from '@/views/adminDashboard.vue'
import SupportDashboardNew from '@/views/SupportDashboardNew.vue'
import DeliveryDashboard from '@/views/DeliveryDashboard.vue'
import CreateQuote from '@/views/CreateQuote.vue'



const routes = [
      {
        path: '/',
        name: 'SignIn',
        component: SignIn
      },
      {
        path: '/admin-dashboard',
        name: 'adminDashboard',
        component: adminDashboard,
      },
      {
        path: '/support-dashboard',
        name: 'SupportDashboardNew',
        component: SupportDashboardNew,
      },
      {
        path: '/delivery-dashboard',
        name: 'DeliveryDashboard',
        component: DeliveryDashboard,
      },
      {
        path: '/create-quote',
        name: 'CreateQuote',
        component: CreateQuote,
      },
  
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

export default router;