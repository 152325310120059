<template>
  <div>
    <!-- Header Section -->
    <header class="header">
      <nav class="d-flex" style="align-items: center; justify-content: space-between; margin-top: -10px;">
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:60px; margin-left:20px"/>
        <div>
          <button class="signin-btn" @click="goToSupportDashboard()" style="margin-right: 30px;">Panel de Soporte</button>
        </div>
      </nav>
    </header>

    <!-- Dashboard Content -->
    <div class="dashboard-container">
      <div style="margin-left:20px; margin-right:20px">
        <div class="card" style="padding-top:20px; padding-left:20px; padding-right:20px">
          <!-- Data Rows -->
          <div v-for="(dataRow, index) in dashboardData" :key="index" class="row mb-4">
            <!-- Date Picker for Each Row -->
            <div v-if="index === 0" class="date-selector">
              <input type="date" v-model="selectedDay" @change="updateData('day')" class="date-input">
            </div>
            <div v-else-if="index === 1" class="date-selector">
              <input type="month" v-model="selectedMonth" @change="updateData('month')" class="date-input">
            </div>
            <div v-else class="date-selector">
              <input type="date" v-model="startDate" @change="updateData('range')" class="date-input">
              a
              <input type="date" v-model="endDate" @change="updateData('range')" class="date-input">
            </div>

            <!-- Data Cards -->
            <div class="col-md-3" v-for="(data, dataIndex) in dataRow.data" :key="dataIndex">
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">{{ data.title }}</h5>
                  <strong class="card-text">{{ data.count }}</strong>
                </div>
              </div>
            </div>
          </div>

          <!-- Optional: Debug Section (Remove in Production) -->
          <!--
          <div>
            <h3>Debug: Dashboard Data</h3>
            <pre>{{ dashboardData }}</pre>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default {
  data() {
    return {
      selectedDay: moment().format('YYYY-MM-DD'),
      selectedMonth: moment().format('YYYY-MM'),
      startDate: moment("2023-08-01").format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      HeaderLogo: require('@/assets/dermapp-white.png'),
      dashboardData: [], // Initialize dashboardData
    };
  },
  computed: {
    ...mapState(['email', 'userId', 'name', 'level', 'country']),
  },
  async mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refresh the token
          const idToken = await user.getIdToken(true);
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error renewing token:', error);
          if (
            error.code === 'auth/id-token-expired' ||
            error.code === 'auth/user-token-expired' ||
            (error.message && error.message.includes('Token'))
          ) {
            localStorage.removeItem('Token');
            this.$router.push('/');
          }
        }
      } else {
        this.$router.push('/');
      }
    });

    // Initialize dashboardData with proper template literals
    this.dashboardData = [
      { title: `${moment(this.selectedDay).format('DD/MM/YYYY')}`, data: [] },
      { title: `${moment(this.selectedMonth, 'YYYY-MM').format('MM/YYYY')}`, data: [] },
      { title: `${moment(this.startDate).format('DD/MM/YYYY')} a ${moment(this.endDate).format('DD/MM/YYYY')}`, data: [] },
    ];

    // Fetch initial data
    await this.getDataForPeriod('day');
    await this.getDataForPeriod('month');
    await this.getDataForPeriod('range');
  },
  methods: {
    ...mapActions(['updateUserId', 'updateEmail', 'updateName', 'updateLevel']),

    goToSupportDashboard() {
      this.$router.push('/support-dashboard');
    },

    async updateData(period) {
      await this.getDataForPeriod(period);
    },

    async getDataForPeriod(period) {
      let params = {};
      let title = '';
      switch (period) {
        case 'day': {
          const formattedDate = moment(this.selectedDay).format('YYYY-MM-DD');
          params.date = formattedDate;
          title = moment(formattedDate).format('DD/MM/YYYY');
          break;
        }
        case 'month': {
          const formattedMonth = moment(this.selectedMonth, 'YYYY-MM').format('YYYY-MM');
          params.month = formattedMonth;
          title = moment(formattedMonth, 'YYYY-MM').format('MM/YYYY');
          break;
        }
        case 'range': {
          const start = this.startDate; // Already in 'YYYY-MM-DD' format
          const end = this.endDate;     // Already in 'YYYY-MM-DD' format
          params.startDate = start;
          params.endDate = end;
          title = `${moment(start).format('DD/MM/YYYY')} a ${moment(end).format('DD/MM/YYYY')}`;
          break;
        }
        default:
          console.warn(`Unknown period: ${period}`);
          return;
      }

      try {
        // Fetch data from three endpoints concurrently
        const token = localStorage.getItem('Token');
        if (!token) {
          console.error('No token found. Redirecting to login.');
          this.$router.push('/');
          return;
        }

        const headers = {
          'Authorization': `Bearer ${token}`,
        };

        // Make concurrent API calls
        const [assessmentsUsersResponse, followupAssessmentsResponse, pharmacyOrdersResponse] = await Promise.all([
          axios.get(`${this.$baseURL}/api/dashboard-assessments-users-info`, { params, headers }),
          axios.get(`${this.$baseURL}/api/dashboard-followup-assessments-info`, { params, headers }),
          axios.get(`${this.$baseURL}/api/dashboard-pharmacy-orders-info`, { params, headers }),
        ]);

        const assessmentsUsersData = assessmentsUsersResponse.data.data;
        const followupAssessmentsData = followupAssessmentsResponse.data.data;
        const pharmacyOrdersData = pharmacyOrdersResponse.data.data;



        // Merge data into a single object
        const mergedData = {
          ...assessmentsUsersData,
          ...followupAssessmentsData,
          ...pharmacyOrdersData,
        };


        // Structure the data as per dashboardData
        const formattedData = [
          { title: 'Usuarios creados', count: mergedData.totalUsers },
          { title: 'Primeras consultas creadas', count: mergedData.totalAssessments },
          { title: 'Primeras consultas pagadas', count: mergedData.totalPaidAssessments },
          { title: 'Conversión de primeras consultas', count: mergedData.conversion },
          { title: 'Consultas globales pagadas', count: mergedData.globalPaidAssessments },
          { title: 'Consultas de seguimiento', count: mergedData.totalFollowupAssessments },
          { title: 'Consultas pagadas de seguimiento', count: mergedData.totalPaidFollowupAssessments },
          { title: 'Conversión de consultas de seguimiento', count: mergedData.followupConversion },
          { title: 'Consultas regaladas', count: mergedData.totalFreeAssessments },
          { title: 'Órdenes de compra creadas', count: mergedData.totalPharmacyOrders },
          { title: 'Órdenes de compra pagadas', count: mergedData.totalPaidPharmacyOrders },
          { title: 'Conversión de órdenes de compra', count: mergedData.pharmacyConversion },
          { title: 'Órdenes de compra canceladas', count: mergedData.totalCanceledPharmacyOrders },
          { title: 'Órdenes de compra reembolsadas', count: mergedData.totalRefundedPharmacyOrders },
          { title: 'Órdenes completas pagadas', count: mergedData.totalCompletePharmacyOrders },     // Newly added
          { title: 'Órdenes parciales pagadas', count: mergedData.totalPartialPharmacyOrders },     // Newly added
        ];


        // Update dashboardData
        let index = ['day', 'month', 'range'].indexOf(period);
        if (index === -1) {
          console.error(`Invalid period: ${period}`);
          return;
        }
        this.dashboardData.splice(index, 1, { title: title, data: formattedData });

      } catch (error) {
        console.error('Error fetching data:', error);
        // Optionally, handle error in UI (e.g., show a notification)
      }
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 60px;
  background: #36b5ff;
  z-index: 1;
}

.date-selector {
  text-align: left;
  margin-bottom: 10px;
}

.date-input {
  padding: 8px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.signin-btn {
  background: white;
  color: #36b5ff;
  border: none;
  height: 30px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
}

.dashboard-container {
  padding: 20px;
}

.card {
  background-color: #f8f9fa;
  border-radius: 8px;
}

.card-body {
  text-align: center;
}

.card-title {
  font-size: 1.2rem;
}

.card-text {
  font-size: 1.5rem;
  color: #007bff;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-md-3 {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 10px;
}
</style>
