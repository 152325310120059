<template>
  <header class="header">
    <nav class="d-flex" style="align-items: center; justify-content: space-between; margin-top: -10px;">
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:60px; margin-left:20px"/>
      <div>
        <span style="color:white; margin-right:10px">Entre </span> 
        <input type="date" v-model="startDate" @change="fetchOrders" class="styled-date-input" style="margin-right: 15px;">
        <span style="color:white; margin-right:10px">y </span> 
        <input type="date" v-model="endDate" @change="fetchOrders" class="styled-date-input" style="margin-right: 30px;">
      </div>
      <div>
        <button class="signin-btn" @click="goToAdminDashboard()" style="margin-right: 30px;">Dashboard</button>
        <button class="signin-btn" @click="goToSupportDashboard()" style="margin-right: 30px;">Panel de Soporte</button>
        <button class="signin-btn" @click="goToQuoteDashboard()" style="margin-right: 30px;">Cotizar</button>
      </div>
      

    </nav>
  </header>
  <div>
      <div  class="card mb-2" style="margin-left:20px; padding-top:10px; height: 87h; width:97vw">
              <!-- Center Column for Personal Data -->
              <div class="row">

      <div v-if="this.mode==0" class="col-md-4" style="width: 69vw; margin-left: 15px; margin-right: -10px">
        <div  class="card mb-2" style="padding-right:10px; padding-left:10px; padding-top:10px; height: 85vh;">
        <div class="card-body">
          <div class="date-selector"> Ordenes
            <input
            type="text"
            v-model="filterText"
            @input="debouncedFilter"
            placeholder="Buscar orden..."
            style="margin-left:15px;"
            />
            <button v-if="hideUnprescripted" class="btn btn-primary" style="width: 15%;margin-left: 20px; font-size:14px" type="text"  @click="togglePrescriptions(0)">Mostrar sin receta</button>
            <button v-else class="btn btn-primary" style="width: 15%; margin-left:20px; font-size:14px" type="text"  @click="togglePrescriptions(1)">Ocultar sin receta</button>
            <button class="btn btn-primary" style="width: 15%; margin-left:20px; font-size:14px" type="text"  @click="toAddProductOrCreateOrder()">Crear orden</button>
            <button @click="downloadCSV" class="btn btn-primary" style="width: 15%; margin-left:20px; font-size:14px" type="text">Descargar ordenes</button>




          </div>
          <div>
            <button :class="buttonClass(0)" class="btn" style="width: 7%; font-size: 9px;" @click="setSelectedState(0)">Creada</button>
            <button :class="buttonClass(2)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(2)">Pagada</button>
            <button :class="buttonClass(1)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(1)">Contacto</button>
            <button :class="buttonClass(5)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(5)">Procesada</button>
            <button :class="buttonClass(6)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(6)"># Pedido</button>
            <button :class="buttonClass(7)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(7)">Remision</button>
            <button :class="buttonClass(8)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(8)">Factura</button>
            <button :class="buttonClass(9)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(9)">Enviada</button>
            <button :class="buttonClass(10)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(10)">Entregada</button>
            <button :class="buttonClass(12)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(12)">Incidencia</button>
            <button :class="buttonClass(13)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(13)">Cancelada</button>
            <button :class="buttonClass(14)" class="btn" style="width: 8%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(14)">Reembolso</button>

            <button :class="buttonClass(11)" class="btn" style="width: 7%; font-size: 9px; margin-left: 5px;" @click="setSelectedState(11)">Ignorar</button>
            
          </div>
          
          <div class="row" style="font-size:10px; display: flex; text-align: center; margin-bottom: 5px; margin-top:10px; width:97%; margin-left:10px;">
            <div class="column" style="flex: 0.2; cursor: pointer;"># Orden</div>
            <div class="column" style="flex: 0.2; cursor: pointer;">Estado</div>
            <div class="column" style="flex: 0.2; cursor: pointer;">ID Usuario</div>
            <div class="column" style="flex: 0.5; cursor: pointer;">Nombre</div>
            <div class="column" style="flex: 0.5; cursor: pointer;">Correo</div>
            <div class="column" style="flex: 0.4; cursor: pointer;">Whatsapp</div>
            <div class="column" style="flex: 0.2; cursor: pointer;">ID Consulta</div>
            <div class="column" style="flex: 0.2; cursor: pointer;">ID Dermatólogo(a)</div> <!-- New Column -->
          </div>
          <div v-if="this.filteredOrders.length>0" class="card" style="height:64vh; overflow-y: auto; overflow-x:hidden">
            <div class="card-body" v-if="isDataLoaded">
              
              <div class="row" 
              style="font-size:12px; display: flex; text-align: center; margin-bottom: 5px; cursor: pointer;" 
              v-for="order in filteredOrders" 
              :key="order.order_id" 
              :class="{ 'selected-row': order.order_id === selectedOrder.order_id }"
              @click="setSelectedOrder(order)">  
                <div class="column" style="flex: 0.2;  ">{{ order.order_id }}</div>

                <div class="column" style="flex: 0.2; ">
                  <div v-if="editingOrderStatus === order.order_id">
                    <select v-model="order.status" @change="handleOrderStatus(order)">
                      <option v-for="(status, index) in orderStatusOptions" :key="index" :value="status">{{ getOrderStatus(status) }}</option>
                    </select>
                  </div>
                  <div v-else @click.stop="startEditingOrderStatus(order.order_id)">{{ getOrderStatus(order.status) }}</div>
                </div>
                <div  class="column" style="flex: 0.2; ">{{ order.user_id  }}</div>
                <div  class="column" style="flex: 0.5; ">{{ getName(order)  }}</div>
                <div  class="column" style="flex: 0.5; ">{{ order.email  }}</div>
                <div class="column" style="flex: 0.4; " @click="openWhatsapp(getPhone(order))">{{ getPhone(order) }}</div>
                <div class="column" style="flex: 0.2; " @click="openAssessment(order.assessment_id)">{{ order.assessment_id }}</div>
                <div class="column" style="flex: 0.2; ">{{ order.assigned_admin_id }}</div> <!-- New Column Data -->
              </div>
            </div>
          </div>
          <div v-else class="card" style="height:64vh; overflow-y: auto; overflow-x:hidden">
            <div class="card-body" v-if="isDataLoaded">
              <div>
                Esta lista esta vacia
              </div>
              
            </div>
          </div>

        </div>
        </div>
      </div>

      

      <!-- Right Column for Consulta Data and Images, etc. -->

      <div class="col-md-4" style="width: 28vw">
        <div v-if="chat_mode==0" class="card mb-2" style="height: 85vh;">
          <div>
            <button class="btn btn-primary" style="width:30%; margin-top:10px; " type="text" @click="toggleChatMode(1)">Transaccion</button>
            <button class="btn btn-primary" style="width:30%; margin-top:10px; margin-left:5px" type="text" @click="toggleChatMode(2)">Envio</button>
            <button class="btn btn-primary" style="width:30%; margin-top:10px; margin-left:5px" type="text" @click="toggleChatMode(3)">Historial</button>

          </div>
          <div class="card-body" style=" overflow-y: auto; overflow-x:hidden;">
            <div class="card" >
              <div v-if="isDataLoaded&&this.filteredOrders.length>0">
                <div class="product-item" style="font-size: 12px; display: flex; text-align: left; margin-bottom: 10px; padding-left: 10px;padding-right: 10px; font-weight: bold; margin-top:20px">
                  <div class="product-code" style="flex: 0.2; margin-left:10px">Código</div>
                  <div class="product-name" style="flex: 0.6; margin-left:20px">Nombre</div>
                  <div class="product-price" style="flex: 0.1; text-align: right; margin-right:40px">Precio</div>
                </div>
                <div v-for="(product, index) in filteredVisibleProducts" :key="index" 
                style="font-size: 12px; display: flex; text-align: left; margin-bottom: 10px; padding-left: 10px;padding-right: 10px; border-bottom: 1px solid #ddd;">
             <div style="display: flex; width: 100%;">
               <div class="product-code" style="flex: 0.2; word-wrap: break-word; white-space: normal">{{ parseSKU(product.sku_magistral) || product.sku }}</div>
               <div class="product-name" style="flex: 0.6; word-wrap: break-word;">
                 {{ product.dose }} {{ parseName(product.name) }} <br>
                 <div v-if="product.quantity > 1" style="font-size: 10px; color: #666; background-color: #f0f0f0; border-radius: 5px; padding: 3px 5px; display: inline-block; margin-top: 2px; margin-bottom:4px">
                   Cantidad: {{ product.quantity }}
                 </div>
               </div>
               <div class="product-price" style="flex: 0.1; text-align: right;">
                ${{ parseFloat(product.priceIVA).toFixed(2) }}
            </div>
                         </div>
           </div>
           

                <div style="padding-right: 10px; padding-left: 10px; font-size:12px; text-align: right">
                  <div>Cantidad de Productos: {{ totalQuantity }}</div>
                  <div>Subtotal: ${{ subtotal.toFixed(2) }}</div>

                  <!-- Cashback section: display only if cashback_amount is present -->
                  <div v-if="cashback_amount && cashback_amount > 0">
                    Cashback: -${{ cashback_amount.toFixed(2) }}
                  </div>

                  <div>Envío: $150.00</div>

                  <!-- Total after considering cashback -->
                  <div>Total: ${{ finalTotal }}</div>
                </div>
                <table style="margin-left: 10px; margin-right: 10px; margin-top: 20px; font-size: 12px; border-collapse: separate; border-spacing: 0 10px;">
                  <tr>
                    <td style="text-align:left; font-weight: bold; width: 20%; border-bottom: 1px solid #ddd">Transaccion</td>
                    <td style="margin-left: 20px; text-align:left; width: 70%; border-bottom: 1px solid #ddd">
                      <div v-for="(id, index) in JSON.parse(selectedOrder.order_uuid)" :key="index" class="product-item">
                        <span class="product-name"> {{ id }} </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left; font-weight: bold; width: 20%; border-bottom: 1px solid #ddd">Receta</td>
                    <td style="margin-left: 20px;text-align:left; width: 70%; border-bottom: 1px solid #ddd">
                      <div style="word-wrap: break-word;">{{selectedOrder.prescription_id}}</div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left; font-weight: bold; width: 20%; border-bottom: 1px solid #ddd">Pedido</td>
                    <td style="margin-left: 20px;text-align:left; width: 70%; border-bottom: 1px solid #ddd">
                      <div v-for="(id, index) in JSON.parse(selectedOrder.sap_id)" :key="index">
                        <span class="product-name"> {{ id.split(' ')[0] }} </span>
                        <span v-if="id.split(' ').length>0" class="product-name" style="margin-left:40px;"> {{ id.split(' ')[1] }} </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left; font-weight: bold; width: 20%; border-bottom: 1px solid #ddd">Factura</td>
                    <td style="margin-left: 20px;text-align:left; width: 70%; border-bottom: 1px solid #ddd">
                      <div style="word-wrap: break-word;">{{selectedOrder.invoice_id}}</div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left; font-weight: bold; width: 20%; border-bottom: 1px solid #ddd">Guia</td>
                    <td style="margin-left: 20px;text-align:left; width: 70%; border-bottom: 1px solid #ddd">
                      <div style="word-wrap: break-word;">{{selectedOrder.tracking_id}}</div>
                    </td>
                  </tr>
                  <tr v-if="selectedOrder.status>=9">
                    <td style="text-align:left; font-weight: bold; width: 20%; border-bottom: 1px solid #ddd">Guia</td>
                    <td style="margin-left: 20px;text-align:left; width: 70%; border-bottom: 1px solid #ddd">
                      <div style="word-wrap: break-word;">{{parseDelivery(selectedOrder.delivery_company)}}</div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align:left; font-weight: bold; width: 20%; vertical-align: top; border-bottom: 1px solid #ddd">Notas</td>
                    <td style="margin-left: 20px; text-align:left; width: 70%; vertical-align: top; border-bottom: 1px solid #ddd">
                      <div style="word-wrap: break-word;" v-html="formattedComments"></div>
                    </td>
                  </tr>
                  
                  
                </table>
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: center;">
            <button v-if="this.selectedState==5" class="btn btn-primary" style="width: 40%; text-align:center; font-size:14px; margin-top: 20px; margin-bottom: 10px; margin-right:10px" type="text" @click="editOrderUUID()">Editar transaccion</button>
            <button v-if="this.selectedState==8" class="btn btn-primary" style="width: 25%; text-align:center; font-size:14px; margin-top: 20px; margin-bottom: 10px; margin-right:10px" type="text" @click="createShipment()">Guia</button>
            <button v-if="this.selectedState==8" class="btn btn-primary" style="width: 25%; text-align:center; font-size:14px; margin-top: 20px; margin-bottom: 10px; margin-right:10px" type="text" @click="getInvoice()">Factura</button>
            <button class="btn btn-primary" style="width: 40%; text-align:center; font-size:14px; margin-top: 20px; margin-bottom: 10px;" type="text" @click="toComments()">Comentarios</button>
          </div>
        </div>

          
          <div v-if="chat_mode==2" class="card mb-2" style=" height: 85vh;">
            <div>
              <button class="btn btn-primary" style="width:30%; margin-top:10px; " type="text"  @click="toggleChatMode(0)">Orden</button>
              <button class="btn btn-primary" style="width:30%; margin-top:10px; margin-left:5px" type="text" @click="toggleChatMode(1)">Transaccion</button>
              <button class="btn btn-primary" style="width:30%; margin-top:10px; margin-left:5px" type="text" @click="toggleChatMode(3)">Historial</button>

            </div>
            <div class="card-body">
              <div class="card" style="max-width:100%; height:100%; overflow-y: auto; overflow-x:hidden">
                <div v-if="isDataLoaded">
                  <table style="margin-left: 10px; margin-right: 10px; margin-top: 20px; font-size: 12px;">
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Nombre</td>
                      <td style="margin-left: 20px; text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.selectedOrder.address_name}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">CP</td>
                      <td style="margin-left: 20px;text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.selectedOrder.postal_code}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Estado</td>
                      <td style="margin-left: 20px;text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.selectedOrder.state}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Ciudad</td>
                      <td style="margin-left: 20px;text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.selectedOrder.city}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Colonia</td>
                      <td style="margin-left: 20px;text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.selectedOrder.district}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Calle</td>
                      <td style="margin-left: 20px;text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.selectedOrder.street}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Número</td>
                      <td style="margin-left: 20px;text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.selectedOrder.number}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Teléfono</td>
                      <td style="text-align:left; width: 80%;"><div style="word-wrap: break-word; word-break: break-all; white-space: normal;">{{this.selectedOrder.address_phone}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Entre calles</td>
                      <td style="margin-left: 20px;text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.selectedOrder.between_streets}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Referencias</td>
                      <td style="margin-left: 20px;text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.selectedOrder.guidelines}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%;">Edad</td>
                      <td style="margin-left: 20px;text-align:left; width: 70%;"><div style="word-wrap: break-word;">{{this.calculateAge(this.selectedOrder.birthday)}}</div></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: center;">
              <button class="btn btn-primary" style="width: 40%; text-align:center; font-size:14px; margin-top: 20px; margin-bottom: 10px;" type="text" @click="toEditAddress()">Editar Direccion</button>
            </div>
          </div>

          <div v-if="chat_mode==1" class="card mb-2" style=" height: 85vh;">
            <div>
              <button class="btn btn-primary" style="width:30%; margin-top:10px; " type="text"  @click="toggleChatMode(0)">Orden</button>
              <button class="btn btn-primary" style="width:30%; margin-top:10px; margin-left:5px" type="text" @click="toggleChatMode(2)">Envio</button>
              <button class="btn btn-primary" style="width:30%; margin-top:10px; margin-left:5px" type="text" @click="toggleChatMode(3)">Historial</button>

            </div>
            <div class="card-body">
              <div class="card" style="width:100%; height:73vh;">
                <div v-if="isTransactionDataLoaded&&selectedOrder.status>=2" style="max-width:100%; height: 100%; overflow-y: auto; overflow-x:hidden">
                  <img style="width:auto; max-height: 100%;" class="assessment-image rounded" :src="selectedOrder.trasactionUrl" alt="Assessment image">
                </div>
                <div v-else-if="selectedOrder.status>=2" style="text-align:center; margin-top: 20px;">
                  <strong>Pago con tarjeta</strong>
                </div>
                <div v-else style="text-align:center; margin-top: 20px;">
                  <strong>Pago pendiente</strong>
                </div>
              </div>
            </div>
          </div>

          <div v-if="chat_mode==3" class="card mb-2" style="height: 85vh;">
            <div>
              <button class="btn btn-primary" style="width:30%; margin-top:10px;" type="text" @click="toggleChatMode(0)">Orden</button>
              <button class="btn btn-primary" style="width:30%; margin-top:10px; margin-left:5px" type="text" @click="toggleChatMode(1)">Transaccion</button>
              <button class="btn btn-primary" style="width:30%; margin-top:10px; margin-left:5px" type="text" @click="toggleChatMode(2)">Envio</button>
            </div>
            <div class="card-body">
              <div class="card" style="max-width:100%; height:100%; overflow-y: auto; overflow-x:hidden">
                <div v-if="isDataLoaded">
                  <table style="margin-left: 10px; margin-right: 10px; margin-top: 20px; font-size: 12px; border-collapse: separate; border-spacing: 0 10px;">
                    <tr>
                      <td style="text-align:left; font-weight: bold; width: 20%; ">Etapa</td>
                      <td style="text-align:left; font-weight: bold; width: 70%; "><div style="word-wrap: break-word;">Horario</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Creado</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.order_created_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Pagado</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.paid_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Contacto</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.contacted_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Motivo</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{this.selectedOrder.contacted_reason}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Procesado</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.processed_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Pedido</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.sapped_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Remisionado</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.remissioned_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Facturado</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.invoiced_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Enviado</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.shipped_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Entregado</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.delivered_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Incidencia</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.incidence_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Motivo</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{this.selectedOrder.incidence_reason}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Cancelado</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.canceled_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Motivo</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{this.selectedOrder.canceled_reason}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Reembolsado</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{formatDateHistory(this.selectedOrder.reimbursed_at)}}</div></td>
                    </tr>
                    <tr>
                      <td style="text-align:left; width: 20%; border-bottom: 1px solid #ddd;">Motivo</td>
                      <td style="text-align:left; width: 70%; border-bottom: 1px solid #ddd;"><div style="word-wrap: break-word;">{{this.selectedOrder.reimbursed_reason}}</div></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          
          
          
            
        </div>
</div> 
      </div>
    </div>
</template>


<script>
import { mapState } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import _ from 'lodash-es'

export default {
  data() {
    return {
      tracking_image: null,
      debouncedFilter: _.debounce(this.handleFilterInput, 500),
      startDate: this.getStartDate(),
      endDate: this.getEndDate(),
      hideUnprescripted: false,
      selectedState: 0,
      orders: [],
      selectedStatus: '',
      mode:0,
      chat_mode:0,
      users: [],
      user: [],
      profile: [],
      assessments: [],
      all_assessments: [],
      profiles: [],
      newMessage: '',
      current_assessment: {},
      HeaderLogo: require('@/assets/dermapp-white.png'),
      isDataLoaded: false,
      isTransactionDataLoaded: false,
      filteredOrders: [],
      filterText: "",
      selectedOrder: null,
      editingOrderStatus: null,
      orderStatusOptions: [0, 2, 1, 5, 6, 7, 8, 9, 10, 12, 13, 14, 11]

    };
  },
  computed: {

    formattedComments() {
    return this.selectedOrder.comments
      ? this.selectedOrder.comments.replace(/\n/g, '<br>')
      : '';
  },
    filteredVisibleProducts() {
    return this.filteredProducts.filter(product => product.sku != 'DHL');
  },
    ...mapState(['email', 'userId', 'name', 'level', 'country']),
    lastTenDigits() {
      return this.selectedOrder.phone.slice(-10);
    },
    filteredProducts() {
      const products = JSON.parse(this.selectedOrder.products);
      return products.filter(product => !(product.sku === '0000000000' || product.sku === '0'));
    },
    buttonClass() {
    return (state) => {
      if (this.selectedState === state) {
        return 'btn-primary';
      } else if (this.filterText !== '' && this.filterOrdersByTextAndState(state).length > 0) {
        return 'btn-secondary';
      } else {
        return 'btn-outline-secondary';
      }
    };
  },
  
  // Total quantity of products
  totalQuantity() {
    return this.filteredProducts.reduce((total, product) => total + product.quantity, 0)-1;
  },

  // Subtotal: total minus fixed shipping cost
  subtotal() {
    if (this.selectedOrder.status>=0) {  //Payed
    return this.selectedOrder.total;    
  } else {                        
    return this.selectedOrder.total-150;
  }
  },

  // Compute cashback_amount, assume it’s available in selectedOrder
  cashback_amount() {
    return this.selectedOrder.cashback_amount || 0; // Use 0 if no cashback is applied
  },

  // Compute the final total considering cashback and shipping
  finalTotal() {
    if (this.selectedOrder.status>=0) {  //Payed
      return (this.selectedOrder.total - this.cashback_amount + 150).toFixed(2); 
    } else {                        
      return (this.selectedOrder.total - this.cashback_amount).toFixed(2); 
    }
  }


  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/'); // Redirigir al login
      }
    });
    
  // Fetch the user data
  this.fetchOrders();
},

  methods: {

    getInvoice() {
      if (this.selectedOrder && this.selectedOrder.invoice_id) {
        const invoiceNumber = `F${this.selectedOrder.invoice_id}`;
        const url = `https://omicronsaproduccion.blob.core.windows.net/invoices/${invoiceNumber}.pdf`;
        
        // Open the invoice in a new tab
        window.open(url, '_blank');
      } else {
        // Handle the case where invoice_id is not available
        console.error('Selected order or invoice ID is missing.');
      }
    },

    createShipment() {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: '<h3>Crear Envío</h3>',
    html:
      '<div style="text-align: center;">' +
      '<small>Ingresa las dimensiones y peso del paquete<br><br></small>' +
      '</div>' +
      '<div style="text-align: center;">' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="number" id="weight" placeholder="Peso (kg)" min="0" step="any">' +
      '</div>' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="number" id="length" placeholder="Largo (cm)" min="0" step="any">' +
      '</div>' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="number" id="width" placeholder="Ancho (cm)" min="0" step="any">' +
      '</div>' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="number" id="height" placeholder="Alto (cm)" min="0" step="any">' +
      '</div>' +
      '</div>',
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    preConfirm: () => {
      const weight = document.getElementById('weight').value;
      const length = document.getElementById('length').value;
      const width = document.getElementById('width').value;
      const height = document.getElementById('height').value;

      // Validate inputs
      if (!weight || weight <= 0) {
        Swal.showValidationMessage("Por favor ingresa un peso válido");
        return false; // Prevent dialog from closing
      }
      if (!length || length <= 0) {
        Swal.showValidationMessage("Por favor ingresa un largo válido");
        return false; // Prevent dialog from closing
      }
      if (!width || width <= 0) {
        Swal.showValidationMessage("Por favor ingresa un ancho válido");
        return false; // Prevent dialog from closing
      }
      if (!height || height <= 0) {
        Swal.showValidationMessage("Por favor ingresa un alto válido");
        return false; // Prevent dialog from closing
      }

      return { weight, length, width, height };
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      // Call createDHLGuide method, passing the collected values
      self.createDHLGuide(result.value.weight, result.value.length, result.value.width, result.value.height);
    }
  });
},

downloadBase64File(base64Data, fileName) {
  const contentType = 'application/pdf';
  const sliceSize = 512;

  // Decode base64 string to binary data
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  // Create a blob from the byte arrays
  const blob = new Blob(byteArrays, { type: contentType });
  const blobUrl = URL.createObjectURL(blob);

  // Create a temporary link to trigger the download
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;

  // Append link to the body (required for Firefox)
  document.body.appendChild(link);

  // Trigger the download by simulating click
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
},



   async createDHLGuide(weight, length, width, height) {
      let shipping_data = {
        order_id: this.selectedOrder.order_id, 
        city: this.selectedOrder.city, 
        postal_code: this.selectedOrder.postal_code, 
        street: this.selectedOrder.street, 
        number: this.selectedOrder.number, 
        district: this.selectedOrder.district, 
        between_streets: this.selectedOrder.between_streets, 
        address_ref: this.selectedOrder.guidelines, 
        phone: this.selectedOrder.address_phone, 
        state: this.selectedOrder.state, 
        name: this.selectedOrder.address_name, 
        weight: weight, 
        length: length, 
        width: width, 
        height: height,
        email: this.selectedOrder.email
      }

  try {
    const response = await axios.post('https://api.dermapp.com/api/create-dhl-guide', shipping_data);

    if(response.status == 200) {
      
    this.tracking_image = response.data.data.tracking_document[0];
    this.selectedOrder.tracking_id = response.data.data.tracking_number;
    this.selectedOrder.status = 9;
    let file_name = this.selectedOrder.order_uuid + '.pdf';
    this.downloadBase64File(this.tracking_image.content, file_name );
    this.updateOrderStatus(this.selectedOrder);
    
    }
    // Handle the response as needed
    

  } catch (error) {
    // Handle errors here
    console.error('Error creating DHL Guide:', error.response || error.message);
    throw error;
  }
},

    handleFilterInput() {
    this.setSelectedState(this.selectedState);
    // The filtering logic is now handled by the computed property
  },

    getStartDate() {
  const date = new Date();
  date.setDate(date.getDate() - 30); // Subtract 30 days from today's date
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
},
getEndDate() {
  const date = new Date();
  date.setDate(date.getDate() + 1); // Add 1 day to get tomorrow's date
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
},
    fetchOrders() {
      const token = localStorage.getItem('Token');
      axios.get(`${this.$baseURL}/api/all-orders-v3?startDate=${this.startDate}&endDate=${this.endDate}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
        .then((orders_response) => {
          this.orders = orders_response.data.data.orders;
          this.filterOrdersByState(this.selectedState);
          this.isDataLoaded = true;
        }).catch((error) => {
          console.error("Error fetching orders data:", error);
        });
    },


    downloadCSV() {
    // Get today's date in the format YYYY-MM-DD
    const today = new Date().toISOString().split('T')[0];

    // Generate the filename using selected state and today's date
    const filename = `ordenes_${this.getOrderStatus(this.selectedState)}_${today}.csv`;

    // Define CSV headers
    const headers = ['Order ID', 'Status', 'Name', 'Phone', 'Assessment ID'];
    
    // Create CSV content
    const csvContent = [
      headers.join(','), // Add the headers row
      ...this.filteredOrders.map(order => [
        order.order_id,
        this.getOrderStatus(order.status),
        this.getName(order),
        this.getPhone(order),
        order.assessment_id
      ].join(','))
    ].join('\n');

    // Add BOM to CSV content to handle special characters
    const bom = '\uFEFF';
    const finalContent = bom + csvContent;

    // Create a Blob from the CSV content
    const blob = new Blob([finalContent], { type: 'text/csv;charset=utf-8;' });
    
    // Create a link element to trigger the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  toEditAddress() {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: `<h3>Editar dirección del pedido #${self.selectedOrder.order_id}</h3>`,
    html:
      `<div style="display: flex; align-items: flex-start;">` +
        `<div style="flex: 0 0 20%; text-align: left; margin-right: 5%; margin-top:15px">` +
          `<label for="street" style="display: block; font-size: 14px; margin-bottom: 20px;">Nombre:</label>` +
          `<label for="number" style="display: block; font-size: 14px; margin-bottom: 20px;">Teléfono:</label>` +
          `<label for="street" style="display: block; font-size: 14px; margin-bottom: 20px;">Calle:</label>` +
          `<label for="number" style="display: block; font-size: 14px; margin-bottom: 20px;">Número:</label>` +
          `<label for="district" style="display: block; font-size: 14px; margin-bottom: 20px;">Colonia:</label>` +
          `<label for="city" style="display: block; font-size: 14px; margin-bottom: 20px;">Ciudad:</label>` +
          `<label for="state" style="display: block; font-size: 14px; margin-bottom: 20px;">Estado:</label>` +
          `<label for="postal_code" style="display: block; font-size: 14px; margin-bottom: 20px;">Código Postal:</label>` +
          `<label for="between_streets" style="display: block; font-size: 14px; margin-bottom: 20px;">Entre Calles:</label>` +
          `<label for="guidelines" style="display: block; font-size: 14px; margin-bottom: 10px;">Indicaciones:</label>` +
        `</div>` +
        `<div style="flex: 0 0 60%; text-align: left;">` +
          `<input id="address_name" type="text" placeholder="Nombre" value="${self.selectedOrder.address_name}" class="swal2-input" style="width: 100%; font-size: 10px; padding: 6px; ">` +
          `<input id="address_phone" type="text" placeholder="Teléfono" value="${self.selectedOrder.address_phone}" class="swal2-input" style="width: 100%; font-size: 10px; padding: 6px;">` +
          `<input id="street" type="text" placeholder="Calle" value="${self.selectedOrder.street}" class="swal2-input" style="width: 100%; font-size: 10px; padding: 6px; ">` +
          `<input id="number" type="text" placeholder="Número" value="${self.selectedOrder.number}" class="swal2-input" style="width: 100%; font-size: 10px; padding: 6px;">` +
          `<input id="district" type="text" placeholder="Colonia" value="${self.selectedOrder.district}" class="swal2-input" style="width: 100%; font-size: 10px; padding: 6px; ">` +
          `<input id="city" type="text" placeholder="Ciudad" value="${self.selectedOrder.city}" class="swal2-input" style="width: 100%; font-size: 10px; padding: 6px; ">` +
          `<input id="state" type="text" placeholder="Estado" value="${self.selectedOrder.state}" class="swal2-input" style="width: 100%; font-size: 10px; padding: 6px; ">` +
          `<input id="postal_code" type="text" placeholder="Código Postal" value="${self.selectedOrder.postal_code}" class="swal2-input" style="width: 100%; font-size: 10px; padding: 6px; ">` +
          `<input id="between_streets" type="text" placeholder="Entre Calles" value="${self.selectedOrder.between_streets}" class="swal2-input" style="width: 100%; font-size: 10px; padding: 6px; ">` +
          `<textarea id="guidelines" placeholder="Indicaciones" class="swal2-textarea" style="width: 100%; font-size: 10px; padding: 6px; resize: none; height: 100px;">${self.selectedOrder.guidelines}</textarea>` +
        `</div>` +
      `</div>`,
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Guardar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    didOpen: () => {
      // Optional: Set focus on the first input field
      document.getElementById('street').focus();

      // Reorder buttons
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
    },
    preConfirm: () => {
      const address_name = document.getElementById('address_name').value;
      const address_phone = document.getElementById('address_phone').value;
      const street = document.getElementById('street').value;
      const number = document.getElementById('number').value;
      const district = document.getElementById('district').value;
      const city = document.getElementById('city').value;
      const state = document.getElementById('state').value;
      const postal_code = document.getElementById('postal_code').value;
      const between_streets = document.getElementById('between_streets').value;
      const guidelines = document.getElementById('guidelines').value;

      // Validate input fields (if needed)
      if (!street || !number || !district || !city || !state || !postal_code || !address_name || !address_phone ) {
        Swal.showValidationMessage("Por favor completa todos los campos obligatorios");
        return false; // Prevent dialog from closing
      }
      
      return {
        address_name,
        address_phone,
        street,
        number,
        district,
        city,
        state,
        postal_code,
        between_streets,
        guidelines
      }; // Return values will be passed to 'then' method
    }
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      // Update the selectedOrder with the new address details
      self.selectedOrder.address_name = result.value.address_name;
      self.selectedOrder.address_phone = result.value.address_phone;
      self.selectedOrder.street = result.value.street;
      self.selectedOrder.number = result.value.number;
      self.selectedOrder.district = result.value.district;
      self.selectedOrder.city = result.value.city;
      self.selectedOrder.state = result.value.state;
      self.selectedOrder.postal_code = result.value.postal_code;
      self.selectedOrder.between_streets = result.value.between_streets;
      self.selectedOrder.guidelines = result.value.guidelines;

      self.updateDeilveryAddress(self.selectedOrder); // Call Vue method to update the order address
    }
  });
},


async updateDeilveryAddress(order) {

  let address = {
    user_id: order.user_id, 
    name: order.address_name, 
    street: order.street, 
    number: order.number, 
    zip: order.postal_code, 
    district: order.district, 
    city: order.city, 
    state: order.state, 
    guidelines: order.guidelines, 
    is_between: order.between_streets, 
    phone: order.address_phone,
  }

  await axios.put(`${this.$baseURL}/api/update-delivery-address-v2`, address);

},


    toComments() {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: `<h3>Comentarios del pedido #${self.selectedOrder.order_id}</h3>`,
    html:
      `<div style="text-align: center;">` +
      `<small>Ingresa los comentarios del pedido<br><br>` +
      `</div>` +
      `<div style="text-align: center;">` +
      `<div class="input-group mb-3" style="justify-content: center; display: flex; width: 100%;">` +
      `<textarea id="cancelReason" placeholder="escribe los comentarios del pedido..." style="width: 80%; height: 100px; padding: 10px; font-size: 16px; resize: none;"></textarea>` +
      `</div>` +
      `</div>`,
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    didOpen: () => {
      // Prepopulate the textarea with the current comments
      const textarea = document.getElementById('cancelReason');
      textarea.value = self.selectedOrder.comments || ''; // Set the textarea value to existing comments

      // Reorder buttons
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
    },
    preConfirm: () => {
      const cancelReason = document.getElementById('cancelReason').value;

      if (!cancelReason) {
        Swal.showValidationMessage("Por favor ingresa el motivo de contacto");
        return false; // Prevent dialog from closing
      }
      return { cancelReason }; // Return value will be passed to 'then' method
    }
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      self.selectedOrder.comments = result.value.cancelReason; // Store the updated comments in the order
      self.updateOrderStatus(self.selectedOrder); // Call Vue method to update order status
    }
  });
},


    parseDelivery(mode){
      if(mode==0){
        return "DHL"
      } else if(mode == 1){
        return "Estafeta"
      } else if(mode == 2){
        return "Fedex"
      }
    },

    productRemission() {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  let remissioned_products = [];

  function getProductListHtml() {
    return JSON.parse(self.selectedOrder.sap_id).map((id, index) => {
      const remissionText = id.split(' ').slice(1).join(' ');
      return `
        <div class="product-item" style="font-size: 12px; display: flex; text-align: left; margin-bottom: 5px; padding-left: 10px; padding-right: 10px;">
          <div class="product-index" style="flex: 0.2;">${index + 1}</div>
          <div class="product-name" style="flex: 0.4; word-wrap: break-word;">${id.split(' ')[0]}</div>
          <div class="product-input" style="flex: 0.4;">
            <input type="text" id="input-${index}" placeholder="Remisión" value="${remissionText}" data-id="${id.split(' ')[0]}" style="width: 100%;">
          </div>
        </div>
      `;
    }).join('');
  }

  function showSwal() {
    const productHtml = getProductListHtml();
    Swal.fire({
      title: '<h3>Remisionar pedidos</h3>',
      html:
        '<div style="display: flex; justify-content: space-between;">' +
        '<div style="flex: 1;">' +
        '<div id="productList">' + productHtml + '</div>' +
        '</div>' +
        '</div>',
      denyButtonText: 'Regresar',
      denyButtonColor: '#0d6efd',
      confirmButtonText: 'Actualizar pedidos',
      confirmButtonColor: '#0d6efd',
      showCloseButton: true,
      showCancelButton: false,
      showDenyButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const updatedIds = [];
        JSON.parse(self.selectedOrder.sap_id).forEach((id, index) => {
          const input = document.getElementById(`input-${index}`);
          const remissionText = input.value.trim();
          if (remissionText) {
            updatedIds.push(`${input.dataset.id} ${remissionText}`);
          } else {
            updatedIds.push(input.dataset.id);
          }
        });

        remissioned_products = updatedIds; // Update the remissioned products
        return { remissioned_products }; // Return value will be passed to 'then' method
      },
      didOpen: () => {
        const confirmButton = document.querySelector('.swal2-confirm');
        const denyButton = document.querySelector('.swal2-deny');
        confirmButton.parentNode.insertBefore(denyButton, confirmButton);
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        self.selectedOrder.sap_id = JSON.stringify(result.value.remissioned_products);
        self.updateOrderStatus(self.selectedOrder);

        // Add any additional actions needed after updating the order
      } else if (result.isDenied) {
        // Handle the deny action if needed
      }
    });
  }

  showSwal(); // Show the Swal alert for the first time
},

editOrderUUID() {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  let updated_order_uuids = [];

  // Helper function to ensure order_uuid is always an array
  function parseOrderUUID() {
    return Array.isArray(self.selectedOrder.order_uuid)
      ? self.selectedOrder.order_uuid
      : JSON.parse(self.selectedOrder.order_uuid || "[]");
  }

  function getOrderUUIDListHtml() {
    return parseOrderUUID().map((uuid, index) => {
      return `
        <div class="uuid-item" style="font-size: 12px; display: flex; text-align: left; margin-bottom: 5px; padding-left: 10px; padding-right: 10px;">
          <div class="uuid-index" style="flex: 0.2;">${index + 1}</div>
          <div class="uuid-input" style="flex: 0.6;">
            <input type="text" id="input-uuid-${index}" value="${uuid}" style="width: 100%;">
          </div>
          <div class="uuid-delete" style="flex: 0.2;">
            <i class="fas fa-trash-alt" id="delete-uuid-${index}" style="cursor: pointer; font-size: 1.5em; margin-left:10px"></i>
          </div>
        </div>
      `;
    }).join('') + `
      <div class="uuid-item" style="font-size: 12px; display: flex; text-align: left; margin-bottom: 5px; padding-left: 10px; padding-right: 10px;">
        <div class="uuid-index" style="flex: 0.2;">New</div>
        <div class="uuid-input" style="flex: 0.8;">
          <input type="text" id="input-new-uuid" placeholder="Add new UUID" style="width: 75%;">
        </div>
      </div>
    `;
  }

  function showSwal() {
    const uuidHtml = getOrderUUIDListHtml();
    Swal.fire({
      title: '<h3>Editar transaccion</h3>',
      html: `
        <div style="display: flex; justify-content: space-between;">
          <div style="flex: 1;">
            <div id="uuidList">${uuidHtml}</div>
          </div>
        </div>
      `,
      denyButtonText: 'Cancelar',
      denyButtonColor: '#0d6efd',
      confirmButtonText: 'Actualizar transaccion',
      confirmButtonColor: '#0d6efd',
      showCloseButton: true,
      showCancelButton: false,
      showDenyButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const updatedUUIDs = [];
        parseOrderUUID().forEach((uuid, index) => {
          const input = document.getElementById(`input-uuid-${index}`);
          const newValue = input.value.trim();
          if (newValue) {
            updatedUUIDs.push(newValue);
          }
        });

        const newUUIDInput = document.getElementById('input-new-uuid');
        const newUUIDValue = newUUIDInput.value.trim();
        if (newUUIDValue) {
          updatedUUIDs.push(newUUIDValue);
        }

        updated_order_uuids = updatedUUIDs; // Update the UUID list
        return { updated_order_uuids }; // Return value will be passed to 'then' method
      },
      didOpen: () => {
        parseOrderUUID().forEach((uuid, index) => {
          const deleteIcon = document.getElementById(`delete-uuid-${index}`);
          deleteIcon.addEventListener('click', () => {
            document.getElementById(`input-uuid-${index}`).value = ''; // Clear the input field to "delete" the UUID
            deleteIcon.parentElement.parentElement.style.display = 'none'; // Hide the row
          });
        });

        const confirmButton = document.querySelector('.swal2-confirm');
        const denyButton = document.querySelector('.swal2-deny');
        confirmButton.parentNode.insertBefore(denyButton, confirmButton);
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        // Ask for confirmation before saving changes
        Swal.fire({
          title: "¿Estás seguro que quieres guardar los cambios?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0d6efd', // Red for Confirm button
          cancelButtonColor: '#0d6efd', // Blue for Cancel button
          confirmButtonText: 'Confirmar cambios',
          cancelButtonText: 'Cancelar',
          reverseButtons: true, // Reverses the order of buttons
        }).then((confirmationResult) => {
          if (confirmationResult.isConfirmed) {
            self.selectedOrder.order_uuid = JSON.stringify(result.value.updated_order_uuids);
            self.updateOrderStatus(self.selectedOrder);
            // Add any additional actions needed after updating the UUIDs
          }
        });
      } else if (result.isDenied) {
        // Handle the deny action if needed
      }
    });
  }

  showSwal(); // Show the Swal alert for the first time
},






    calculateAge(birthday) {
    const birthdate = new Date(birthday);
    const ageDifMs = Date.now() - birthdate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },

    toAddProductOrCreateOrder() {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  
  function getProductListHtml(products) {
    return products.map((product, index) => `
    <div class="product-item" style="font-size: 12px; display: flex; text-align: left; margin-bottom: 5px; padding-left: 10px;padding-right: 10px;">
      <div class="product-index" style="flex: 0.1;">${ index + 1 }</div>
      <div class="product-code" style="flex: 0.4;word-wrap: break-word; white-space: normal">${ product.sku_magistral || product.sku }</div>
      <div class="product-name" style="flex: 0.4;word-wrap: break-word;">${ product.dose }</div>
      <div class="product-quantity" style="flex: 0.1;">${ product.quantity }</div>
    </div>
    `).join('');
  }

  function showSwal(order_assessment_id = '') {
    const productHtml = getProductListHtml(self.products);
    Swal.fire({
      title: '<h3>Crear orden de compra</h3>',
      html:
        '<div style="display: flex; justify-content: space-between;">' +
        '<div style="flex: 1; padding-right: 20px;">' +
        '<div style="text-align: center;">' +
        '<small>Ingresa el ID de consulta<br><br></small>' +
        '</div>' +
        '<div style="text-align: center;">' +
        '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
        `<input type="text" id="assessmentId" placeholder="ID de consulta" value="${order_assessment_id}">` +
        '</div>' +
        '<small>Ingresa los detalles del producto<br><br></small>' +
        '</div>' +
        '<div style="text-align: center;">' +
        '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
        '<input type="text" id="productName" placeholder="Nombre del producto">' +
        '</div>' +
        '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
        '<input type="text" id="productSku" placeholder="Codigo SAP del producto">' +
        '</div>' +
        '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
        '<input type="text" id="productQuantity" placeholder="Cantidad">' +
        '</div>' +
        '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
        '<input type="text" id="productPrice" placeholder="Precio">' +
        '</div>' +
        '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
        '<input type="text" id="productDose" placeholder="Tamaño">' +
        '</div>' +
        '</div>' +
        '</div>' +
        '<div style="flex: 1;">' +
        '<div id="productList">' + productHtml + '</div>' +
        '</div>' +
        '</div>',
      denyButtonText: 'Crear Pedido',
      denyButtonColor: '#0d6efd',
      confirmButtonText: 'Agregar Producto',
      confirmButtonColor: '#0d6efd',
      showCloseButton: true,
      showCancelButton: false,
      showDenyButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const name = document.getElementById('productName').value;
        const sku = document.getElementById('productSku').value;
        const quantity = document.getElementById('productQuantity').value;
        const price = document.getElementById('productPrice').value;
        const dose = document.getElementById('productDose').value;
        const order_assessment_id = document.getElementById('assessmentId').value;

        if (!name || !sku || !quantity || !price) {
          Swal.showValidationMessage("Por favor ingresa todos los detalles del producto");
          return false; // Prevent dialog from closing
        }

        return { name, sku, quantity, price, dose, order_assessment_id }; // Return value will be passed to 'then' method
      },
      didOpen: () => {
        const confirmButton = document.querySelector('.swal2-confirm');
        const denyButton = document.querySelector('.swal2-deny');
        confirmButton.parentNode.insertBefore(denyButton, confirmButton);
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const product = {
          name: result.value.name,
          sku: result.value.sku,
          sku_magistral: result.value.sku,
          quantity: parseInt(result.value.quantity),
          price: parseFloat(result.value.price),
          priceIVA: parseFloat(result.value.price), // Assuming IVA is 16%
          dose: result.value.dose || ''
        };

        self.products.push(product); // Add the product to the products array

        // Reopen the Swal alert to add more products or create the order
        showSwal(result.value.order_assessment_id);
      } else if (result.isDenied) {
        const order_assessment_id = document.getElementById('assessmentId').value;
        self.createOrder(order_assessment_id); // Call the createOrder method to create the order
      }
    });
  }

  // Initialize the products array if not already done
  if (!self.products) {
    self.products = [];
  }

  showSwal(); // Show the Swal alert for the first time
},

async createOrder(order_assessment_id) {
  let checkout = {};
  let total = this.products.reduce((acc, product) => acc + product.priceIVA * product.quantity, 0);
  total = total + 150.00;
  let delivery = {
    name: 'Envio nacional',
    sku: 'DHL',
    sku_magistral: 'DHL',
    quantity: 1,
    price: "150.00",
    priceIVA: "150.00",
    dose: ''
  };

  this.products.push(delivery);
  checkout.products = this.products;
  checkout.total = total;
  checkout.assessment_id = order_assessment_id;

  try {
    // Make a POST request to your API endpoint to create the order
    const response = await axios.post(`${this.$baseURL}/api/create-pharmacy-order`, checkout);
    if (response.status === 200) {
      // If the request was successful, save the new assessment id
      this.updateOrders();
    } else {
      console.error('Error creating order:', response);
    }
  } catch (error) {
    console.error('Error creating order:', error);
  }
},
   
    updateOrders(){
      const token = localStorage.getItem('Token');
      axios.get(`${this.$baseURL}/api/all-orders`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
    .then((orders_response) => {
      this.orders = orders_response.data.data.orders;
      this.filterOrdersByState(this.selectedState);  });
    },

    togglePrescriptions(mode) {
      if(mode==0){
        this.hideUnprescripted = false;
        this.filterOrdersByState(this.selectedState) 

      } else {
        this.hideUnprescripted = true;
        this.filterOrdersByState(this.selectedState) 

      }
    },

    openAssessment(id) {
    
      let url = `${this.$baseURLPacientes}/assessment-diagnosis-new/${id}`;
      window.open(url, '_blank');


},

formatDate(dateString) {
  if(dateString!=null){
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };

    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Subtract 6 hours from the date
    date.setHours(date.getHours() - 6);

    // Return the adjusted date formatted as a string
    return date.toLocaleDateString('es-ES', options);
  } else {
    return 'pendiente'
  }
    
},

formatDateHistory(dateString) {
  if (!dateString) return '';

  const date = new Date(dateString);

  // Format options for date
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('es-ES', dateOptions);

  // Format options for time (12-hour format)
  const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
  const formattedTime = date.toLocaleTimeString('es-ES', timeOptions);

  return `${formattedDate} ${formattedTime}`;
},


    getName(order){
      if(order.name!=undefined && order.name!=''){
        return order.name 
      } else { return order.address_name}
    },

    getPhone(order){
      if(order.phone!=undefined && order.phone!=''){
        return order.phone 
      } else { return order.address_phone}
    },

    toggleChatMode(mode){
      this.chat_mode=mode;
    },

    sortUsersByMessageStatus() {
    this.filteredUsers.sort((a, b) => b.new_message - a.new_message);
  },

  sortAssessmentsByMessageStatus() {
  this.assessments = [...this.assessments].sort((a, b) => b.new_message - a.new_message);
},


sortAllAssessmentsByMessageStatus() {
  this.filteredAssessments = [...this.filteredAssessments].sort((a, b) => b.new_message - a.new_message);
},

sortUsersByOnboardingStatus() {
    this.filteredUsers.sort((a, b) => a.onboarded - b.onboarded);
  },

  sortAssessmentsByPaymentStatus() {
  this.assessments = [...this.assessments].sort((a, b) => a.status - b.status);
},


sortAllAssessmentsByPaymentStatus() {
  this.filteredAssessments = [...this.filteredAssessments].sort((a, b) => a.status - b.status);
},

sortAssessmentsByDiagnosisStatus() {
  this.assessments = [...this.assessments].sort((a, b) => a.is_diagnosed - b.is_diagnosed);
},


sortAllAssessmentsByDiagnosisStatus() {
  this.filteredAssessments = [...this.filteredAssessments].sort((a, b) => a.is_diagnosed - b.is_diagnosed);
},

sortAssessmentsByTreatmentStatus() {
  this.assessments = [...this.assessments].sort((a, b) => a.has_treatment - b.has_treatment);
},


sortAllAssessmentsByTreatmentStatus() {
  this.filteredAssessments = [...this.filteredAssessments].sort((a, b) => a.has_treatment - b.has_treatment);
},

sortAssessmentsBySymptomsStatus() {
  this.assessments = [...this.assessments].sort((a, b) => b.has_symptoms - a.has_symptoms);
},


sortAllAssessmentsBySymptomsStatus() {
  this.filteredAssessments = [...this.filteredAssessments].sort((a, b) => b.has_symptoms - a.has_symptoms);
},


  startEditingOrderStatus(orderId) {
    this.editingOrderStatus = orderId;
  },

  async updateOrderStatus(order) {
  let order_data = {
    order_id: order.order_id,
    status: order.status,
    order_uuid: order.order_uuid,
    sap_id: order.sap_id,
    invoice_id: order.invoice_id,
    tracking_id: order.tracking_id,
    prescription_id: order.prescription_id,
    phone: order.phone,
    email: order.email,
    patient_name: order.name,
    is_delivered: order.is_delivered,
    delivery_company: order.delivery_company,
    incidence_reason: order.incidence_reason,
    canceled_reason: order.canceled_reason,
    reimbursed_reason: order.reimbursed_reason,
    contacted_reason: order.contacted_reason,
    comments: order.comments,
  };

  await axios.put(`${this.$baseURL}/api/update-order-status-v4`, order_data);

  // Update the local timestamp based on the order status
  const currentTime = new Date().toISOString(); // Get current timestamp in ISO format

  switch (order.status) {
    case 1:
      order.contacted_at = currentTime;
      break;
    case 2:
      order.paid_at = currentTime;
      break;
    case 5:
      order.processed_at = currentTime;
      break;
    case 6:
      order.sapped_at = currentTime;
      break;
    case 7:
      order.remissioned_at = currentTime;
      break;
    case 8:
      order.invoiced_at = currentTime;
      break;
    case 9:
      order.shipped_at = currentTime;
      break;
    case 10:
      order.delivered_at = currentTime;
      break;
      case 12:
      order.incidence_at = currentTime;
      break;
      case 13:
      order.canceled_at = currentTime;
      break;
      case 14:
      order.reimbursed_at = currentTime;
      break;
    default:
      break;
  }

  this.editingOrderStatus = null;
  this.filterOrdersByState(this.selectedState);
},


  getOrderStatus(state) {
    switch(state) {
      case 0: return "creada";
      case 1: return "contactado";
      case 2: return "pagada";
      case 5: return "procesada";
      case 6: return "pedido";
      case 7: return "remisionada";
      case 8: return "facturada";
      case 9: return "enviada";
      case 10: return "entregada";
      case 11: return "ignorar";
      case 12: return "incidencia";
      case 13: return "cancelada";
      case 14: return "reembolsada";

    }
  },

  parseSKU(state) {
    switch(state) {
      case "Farma1167": return "Farma1192";
      case "Farma1168": return "Farma1190";
      case "Farma1169": return "Farma1194";
      case "Farma1170": return "Farma1193";
      case "Farma1171": return "Farma1187";
      case "Farma1172": return "Farma1188";
      default: return state; // Optional, for values other than 0, 1, or 2
    }
  },

  parseName(state) {
    switch(state) {
      case "PHYSAC AGUA MICELAR 200ML": return "BIOLOGY AC AGUA MICELAR 200ML";
      case "PHYSAC CREMA COMPENSADORA 40ML": return "BIOLOGY AC HYDRA 40ML";
      case "PHYSAC GEL LIMPIADOR 200ML": return "BIOLOGY AC GEL ESPUMOSO PURIFICANTE 200ML";
      case "PHYSAC GEL LIMPIADOR 400ML": return "BIOLOGY AC GEL ESPUMOSO PURIFICANTE 400ML";
      case "PHYSAC GLOBAL CREMA 40ML": return "BIOLOGY AC GLOBAL 40ML";
      case "PHYSAC PERFECT FLUIDO 40ML": return "BIOLOGY AC PERFECT 40ML";
 
      default: return state; // Optional, for values other than 0, 1, or 2
    }
  },

  handleOrderStatus(order) {

    switch(order.status) {
      case 1: return this.toContacted(order);
      case 4: return this.toPartial(order);
      case 5: return this.toTotal(order);
      case 6: return this.toSap(order);
      case 8: return this.toInvoice(order);
      case 9: return this.toTracking(order);
      case 12: return this.toIncidence(order);
      case 13: return this.toCancel(order);
      case 14: return this.toReimbursement(order);

      default: return this.updateOrderStatus(order); // Optional, for values other than 0, 1, or 2
    }
  },

  toContacted(order) {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: '<h3>Contactar usuario por pedido</h3>',
    html:
      '<div style="text-align: center;">' +
      '<small>Ingresa el motivo de contacto<br><br></small>' +
      '</div>' +
      '<div style="text-align: center;">' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex; width: 100%;">' +
      '<textarea id="cancelReason" placeholder="describe el problema" style="width: 80%; height: 100px; padding: 10px; font-size: 16px; resize: none;"></textarea>' +
      '</div>' +
      '</div>',
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    preConfirm: () => {
      const cancelReason = document.getElementById('cancelReason').value;

      if (!cancelReason) {
        Swal.showValidationMessage("Por favor ingresa el motivo de contacto");
        return false; // Prevent dialog from closing
      }
      return { cancelReason }; // Return value will be passed to 'then' method
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      order.contacted_reason = result.value.cancelReason; // Store the cancellation reason in the order
      self.updateOrderStatus(order); // Call Vue method to update order status
    }
  });
},

  toIncidence(order) {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: '<h3>Problema con Pedido</h3>',
    html:
      '<div style="text-align: center;">' +
      '<small>Ingresa el problema con el pedido<br><br></small>' +
      '</div>' +
      '<div style="text-align: center;">' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex; width: 100%;">' +
      '<textarea id="cancelReason" placeholder="describe el problema" style="width: 80%; height: 100px; padding: 10px; font-size: 16px; resize: none;"></textarea>' +
      '</div>' +
      '</div>',
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    preConfirm: () => {
      const cancelReason = document.getElementById('cancelReason').value;

      if (!cancelReason) {
        Swal.showValidationMessage("Por favor ingresa el motivo de incidencia");
        return false; // Prevent dialog from closing
      }
      return { cancelReason }; // Return value will be passed to 'then' method
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      order.incidence_reason = result.value.cancelReason; // Store the cancellation reason in the order
      self.updateOrderStatus(order); // Call Vue method to update order status
    }
  });
},

  toCancel(order) {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: '<h3>Cancelar Pedido</h3>',
    html:
      '<div style="text-align: center;">' +
      '<small>Ingresa el motivo de la cancelación<br><br></small>' +
      '</div>' +
      '<div style="text-align: center;">' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex; width: 100%;">' +
      '<textarea id="cancelReason" placeholder="razón de cancelación" style="width: 80%; height: 100px; padding: 10px; font-size: 16px; resize: none;"></textarea>' +
      '</div>' +
      '</div>',
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    preConfirm: () => {
      const cancelReason = document.getElementById('cancelReason').value;

      if (!cancelReason) {
        Swal.showValidationMessage("Por favor ingresa la razón de la cancelación");
        return false; // Prevent dialog from closing
      }
      return { cancelReason }; // Return value will be passed to 'then' method
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      order.canceled_reason = result.value.cancelReason; // Store the cancellation reason in the order
      self.updateOrderStatus(order); // Call Vue method to update order status
    }
  });
},


toReimbursement(order) {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: '<h3>Reembolsar Pedido</h3>',
    html:
      '<div style="text-align: center;">' +
      '<small>Ingresa el motivo del reembolso<br><br></small>' +
      '</div>' +
      '<div style="text-align: center;">' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
        '<textarea id="cancelReason" placeholder="motivo del reembolso" style="width: 80%; height: 100px; padding: 10px; font-size: 16px; resize: none;"></textarea>' +
        '</div>' +
      '</div>',
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    preConfirm: () => {
      const cancelReason = document.getElementById('cancelReason').value;

      if (!cancelReason) {
        Swal.showValidationMessage("Por favor ingresa el motivo del reembolso");
        return false; // Prevent dialog from closing
      }
      return { cancelReason }; // Return value will be passed to 'then' method
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      order.reimbursed_reason = result.value.cancelReason; // Store the cancellation reason in the order
      self.updateOrderStatus(order); // Call Vue method to update order status
    }
  });
},

  

  toPartial(order) {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: '<h3>Pedido parcial</h3>',
    html:
      '<div style="text-align: center;">' +
      '<small>Ingresa el numero de la transaccion<br><br></small>' +
      '</div>' +
      '<div style="text-align: center;">' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="text" id="orderUUID" placeholder="numero de transaccion">' +
      '</div>' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="text" id="prescriptionId" placeholder="numero de receta">' +
      '</div>' +
      '</div>',
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    preConfirm: () => {
      const orderUUID = document.getElementById('orderUUID').value;
      const prescriptionId = document.getElementById('prescriptionId').value;

      if (!orderUUID) {
        Swal.showValidationMessage("Por favor ingresa el numero de transaccion");
        return false; // Prevent dialog from closing
      }
      return { orderUUID, prescriptionId }; // Return value will be passed to 'then' method
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
  const denyButton = document.querySelector('.swal2-deny');
  confirmButton.parentNode.insertBefore(denyButton, confirmButton);

    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {

      const orderUUIDArray = JSON.parse(order.order_uuid); // Correctly handle JSON parsing
      orderUUIDArray.pop();
      orderUUIDArray.push(result.value.orderUUID);
      order.order_uuid = JSON.stringify(orderUUIDArray);

        order.prescription_id=result.value.prescriptionId || 'pendiente'
      
      self.updateOrderStatus(order); // Call Vue method with template name and tags
    }
  });
},

toTotal(order) {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: '<h3>Pedido total</h3>',
    html:
      '<div style="text-align: center;">' +
      '<small>Ingresa el numero de la transaccion<br><br></small>' +
      '</div>' +
      '<div style="text-align: center;">' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="text" id="orderUUID" placeholder="numero de transaccion">' +
      '</div>' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="text" id="prescriptionId" placeholder="numero de receta">' +
      '</div>' +
      '</div>',
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    preConfirm: () => {
      const orderUUID = document.getElementById('orderUUID').value;
      const prescriptionId = document.getElementById('prescriptionId').value;

      if (!orderUUID) {
        Swal.showValidationMessage("Por favor ingresa el numero de transaccion");
        return false; // Prevent dialog from closing
      }
      return { orderUUID, prescriptionId }; // Return value will be passed to 'then' method
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
  const denyButton = document.querySelector('.swal2-deny');
  confirmButton.parentNode.insertBefore(denyButton, confirmButton);

    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      const orderUUIDArray = JSON.parse(order.order_uuid); // Correctly handle JSON parsing
      if(orderUUIDArray[0]=='pendiente'){
      orderUUIDArray.pop();
      orderUUIDArray.push(result.value.orderUUID);
      order.order_uuid = JSON.stringify(orderUUIDArray);      } 
      else {
      orderUUIDArray.push(result.value.orderUUID);
      order.order_uuid = JSON.stringify(orderUUIDArray);      }
      if(order.prescription_id=='pendiente'){
          order.prescription_id=result.value.prescriptionId || 'pendiente'
        }
      
      self.updateOrderStatus(order); // Call Vue method with template name and tags
    }
  });
},

toSap(order) {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  
  function getProductListHtml(order) {
    return order.map((id, index) => `
    <div class="product-item" style="font-size: 12px; display: flex; text-align: left; margin-bottom: 5px; padding-left: 10px;padding-right: 10px;">
      <div class="product-index" style="flex: 0.1;">${ index + 1 }</div>
      <div class="product-name" style="flex: 0.4;word-wrap: break-word;">${ id.split(' ')[0] }</div>
    </div>
    `).join('');
  }

  function showSwal(order) {
    const productHtml = getProductListHtml(JSON.parse(order.sap_id));
    let new_sap_id = '';
    Swal.fire({
      title: '<h3>Ingresar numeros de pedido</h3>',
      html:
        '<div style="display: flex; justify-content: space-between;">' +
        '<div style="flex: 0.6;">' +
        '<div style="text-align: center;">' +
        '<small>Ingresa el ID de SAP del pedido<br><br></small>' +
        '</div>' +
        '<div style="text-align: center;">' +
        '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
        `<input type="text" id="sapId" placeholder="ID de SAP" value="${new_sap_id}">` +
        '</div>' +
        '</div>' +
        '</div>' +
        '<div style="flex: 0.4;">' +
        '<div id="productList">' + productHtml + 
        '</div>' +
        '</div>' +
        '</div>',
      denyButtonText: 'Actualizar orden',
      denyButtonColor: '#0d6efd',
      confirmButtonText: 'Agregar pedido',
      confirmButtonColor: '#0d6efd',
      showCloseButton: true,
      showCancelButton: false,
      showDenyButton: true,
      focusConfirm: false,
      preConfirm: () => {
 
        const new_sap_id = document.getElementById('sapId').value;

        if (!new_sap_id) {
          Swal.showValidationMessage("Por favor ingresa un numero de pedido");
          return false; // Prevent dialog from closing
        }

        return { new_sap_id }; // Return value will be passed to 'then' method
      },
      didOpen: () => {
        const confirmButton = document.querySelector('.swal2-confirm');
        const denyButton = document.querySelector('.swal2-deny');
        confirmButton.parentNode.insertBefore(denyButton, confirmButton);
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const sapIdArray = JSON.parse(order.sap_id); // Correctly handle JSON parsing
      if(sapIdArray[0]=='pendiente'){
      sapIdArray.pop();
      sapIdArray.push(result.value.new_sap_id);
      order.sap_id = JSON.stringify(sapIdArray);      } 
      else {
      sapIdArray.push(result.value.new_sap_id);
      order.sap_id = JSON.stringify(sapIdArray);      }  

        // Reopen the Swal alert to add more products or create the order
        showSwal(order);
      } else if (result.isDenied) {
        self.updateOrderStatus(order);
      }
    });
  }

  showSwal(order); // Show the Swal alert for the first time
},

toInvoice(order) {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: '<h3>Facturación</h3>',
    html:
      '<div style="text-align: center;">' +
      '<small>Ingresa el número de la factura<br><br></small>' +
      '</div>' +
      '<div style="text-align: center;">' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="text" id="orderUUID" placeholder="número de factura">' +
      '</div>' +
      '</div>',
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    preConfirm: () => {
      const orderUUID = document.getElementById('orderUUID').value;
      
      // Validation for 6 digits
      if (!orderUUID) {
        Swal.showValidationMessage("Por favor ingresa el número de factura");
        return false; // Prevent dialog from closing
      } else if (orderUUID.length !== 6 || !/^\d{6}$/.test(orderUUID)) {
        Swal.showValidationMessage("El número de factura debe tener 6 dígitos");
        return false; // Prevent dialog from closing
      }
      return { orderUUID }; // Return value will be passed to 'then' method
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      order.invoice_id = result.value.orderUUID;
      self.updateOrderStatus(order); // Call Vue method with template name and tags
    }
  });
},




toTracking(order) {
  const self = this; // Capture 'this' to use Vue instance inside Swal
  Swal.fire({
    title: '<h3>Número de guía</h3>', // Added accent to "Número"
    html:
      '<div style="text-align: center;">' +
      '<small>Ingresa el número de guía del pedido<br><br></small>' + // Added accent to "número"
      '</div>' +
      '<div style="text-align: center;">' +
      '<div class="input-group mb-3" style="justify-content: center; display: flex;">' +
      '<input type="text" id="orderUUID" placeholder="número de guía">' + // Added accent to "número"
      '</div>' +
      '</div>' +
      '<div style="text-align: center; margin-top: 10px;">' +
      '<label><input type="radio" name="delivery_company" value="0" checked> DHL</label>' +
      '<label style="margin-left: 20px;"><input type="radio" name="delivery_company" value="1"> Estafeta</label>' +
      '<label style="margin-left: 20px;"><input type="radio" name="delivery_company" value="2"> Fedex</label>' +
      '</div>',
    denyButtonText: 'Regresar',
    denyButtonColor: '#0d6efd',
    confirmButtonText: 'Continuar',
    confirmButtonColor: '#0d6efd',
    showCloseButton: true,
    showCancelButton: false,
    showDenyButton: true,
    focusConfirm: false,
    preConfirm: () => {
      const orderUUID = document.getElementById('orderUUID').value;
      const deliveryCompany = document.querySelector('input[name="delivery_company"]:checked').value;

      // Validation for 10 digits
      if (!orderUUID) {
        Swal.showValidationMessage("Por favor ingresa el número de guía"); // Added accent to "número"
        return false; // Prevent dialog from closing
      } 
      
      return { orderUUID, deliveryCompany }; // Return values will be passed to 'then' method
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm');
      const denyButton = document.querySelector('.swal2-deny');
      confirmButton.parentNode.insertBefore(denyButton, confirmButton);
    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      order.tracking_id = result.value.orderUUID;
      order.delivery_company = result.value.deliveryCompany;
      self.updateOrderStatus(order); // Call Vue method with template name and tags
    }
  });
},




  

    openWhatsapp(phone) {
        let digitsOnly = '';

    // Check if phone starts with a '+', remove it
    if (phone.startsWith('+')) {
        digitsOnly = phone.substring(1);
    }

    // Construct the URL with the processed phone number
    let url = `https://api.whatsapp.com/send?phone=${digitsOnly}`;

    // Open the URL
    window.open(url, '_blank');
},

    filterOrdersByText() {
    
      if (this.filterText !== "") {
      const searchText = this.filterText.toLowerCase();
      this.filteredOrders = this.orders.filter(order => order.status === this.selectedState);
      this.filteredOrders = this.filteredOrders.filter(order => 
      order.email.toLowerCase().includes(searchText) || 
      order.order_uuid.toLowerCase().includes(searchText) || 
      order.sap_id.includes(searchText) || 
      order.invoice_id.includes(searchText) || 
      order.prescription_id.includes(searchText) ||
      order.tracking_id.includes(searchText) || 
      order.assessment_id == searchText || 
      order.order_id == searchText || 
        order.name.toLowerCase().includes(searchText) || 
        order.phone.includes(searchText) 
      )
      this.filteredOrders = this.filteredOrders.splice(-100);
      this.selectedOrder = this.filteredOrders[0];
    } else {
      this.filterOrdersByState(this.selectedState);
    }
  
    
  },

  setCurrentAssessment(assessment){
   
    this.current_assessment = assessment;
    this.fetchAssessmentProfiles(this.current_assessment);
    try{
      this.current_assessment.messages = JSON.parse(this.current_assessment.messages);
    } catch {
      console.log("continue");
    }
  },

  setSelectedOrder(order){
   this.selectedOrder = order;
   this.isTransactionDataLoaded= false;
   this.getOrderFiles(order);
   
 },

 async getOrderFiles(order){
  const token = localStorage.getItem('Token');
  try {
    const response = await axios.get(`${this.$baseURL}/api/get-files`, {
      params: {
        assessment_id: this.selectedOrder.assessment_id,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    const transactionFiles = response.data.data.filter(file => file.file_type === 3);

    if(transactionFiles && transactionFiles.length>0){
      order.trasactionUrl =`https://dermapp-documents.s3.amazonaws.com/receipts/${transactionFiles[transactionFiles.length-1].file_name}`;

     this.isTransactionDataLoaded= true;
    }
        

} catch (error) {
    console.error('Error fetching trasnaction files:', error);
  }
},

 setSelectedState(state){
   this.selectedState = state;
   this.filterOrdersByState(this.selectedState);
   this.filterOrdersByText();
 },

    goToAdminDashboard() {
      this.$router.push('/admin-dashboard');
    },

    goToSupportDashboard() {
      this.$router.push('/support-dashboard');
    },

    goToQuoteDashboard() {
      this.$router.push('/create-quote');
    },

    filterOrdersByState(state) {
      if(this.hideUnprescripted){
        this.filteredOrders = this.orders.filter(order => {
    return order.status === state && order.prescription_id!='pendiente';
  });
      } else {
        this.filteredOrders = this.orders.filter(order => {
    return order.status === state;
  });
      }
      this.filteredOrders = this.filteredOrders.splice(-100);
  
  this.selectedOrder = this.filteredOrders.length > 0 ? this.filteredOrders[0] : null;
},

filterOrdersByTextAndState(state) {
    if (this.filterText !== "") {
      const searchText = this.filterText.toLowerCase();
      let filteredOrders = this.orders.filter(order => order.status === state);
      return filteredOrders.filter(order => 
        order.name.toLowerCase().includes(searchText) || 
        order.email.toLowerCase().includes(searchText) || 
        order.order_uuid.toLowerCase().includes(searchText) || 
        order.sap_id.includes(searchText) || 
        order.invoice_id.includes(searchText) || 
        order.prescription_id.includes(searchText) ||
        order.tracking_id.includes(searchText) ||
        order.phone.includes(searchText) ||
        order.assessment_id == searchText || 
      order.order_id == searchText

      );
    } else {
      return this.orders.filter(order => order.status === state);
    }
  },


   
  },
};
</script>

<style scoped>



.header {
    width: 100%;
    height: 60px;
    background: #36b5ff;
    z-index: 1;
  }

  .date-selector {
    text-align: left;
    margin-bottom: 10px;
  }

  .date-input {
    padding: 8px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  .product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .product-name {
    margin-right: 10px; /* Adjust as needed for spacing */
  }

  .styled-date-input {
    background: white;
    color: #36b5ff;
    border: none;
    height: 30px;
    padding: 0 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
    font-size: 14px;
    line-height: 30px;
  }
  
  .styled-date-input::-webkit-calendar-picker-indicator {
    cursor: pointer;
    color: #36b5ff;
    opacity: 0.5;
  }
  
  .styled-date-input:hover {
    background-color: #e0f4ff;
  }
  
  
.signin-btn {
  background: white;
  color: #36b5ff;
  border: none;
  height: 30px;
  padding-right:10px;
  padding-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
  }

  

  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .selected-row {
    background-color: #e0e0e0; /* Example: light gray background */
    /* Other styling as needed */
  }

</style>
